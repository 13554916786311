import { useState, useEffect, useMemo } from 'react';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import {
  query,
  getDocs,
  collection,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { Button, Input } from 'reactstrap';
import { MdChevronLeft } from 'react-icons/md';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { db } from '../../../util/firebase';
import { generateUID } from '../../../util/token';
import { getBase64 } from '../../../util/photos';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { CustomToast } from '../../../components/Notifications/CustomToast';

export function AdminEditRecomendationPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [listCategory, setListCategory] = useState([]);
  const [infoRecomendation, setInfoRecomendation] = useState(null);
  const [selectCategory, setSelectCategory] = useState(null);
  const [newProductAsin, setNewProductAsin] = useState('');
  const [newProductName, setNewProductName] = useState('');
  const [photoProduct, setPhotoProduct] = useState(null);
  const [photoPreview, setPhotoPreview] = useState('');
  const [newProductWeight, setNewProductWeight] = useState('0');
  const [price, setPrice] = useState('0');
  const [reviewsNumber, setReviewsNumber] = useState('0');
  const [startProduct, setStarProduct] = useState('0');
  const [statusProduct, setStatusProduct] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const categoryRef = collection(db, 'RecommendedWishlistCategories');
      const q = query(categoryRef);
      const querySnapshot = await getDocs(q);
      const optionsCategory = [];
      querySnapshot.forEach((doc, i) => {
        const category = doc.data();
        optionsCategory.push({
          name: category.name,
          id: category.id,
        });
        setSelectCategory(category.id);
      });
      setListCategory(optionsCategory);
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchRecomendation = async () => {
      if (!id) return;
      try {
        setIsLoading(true);
        const q = doc(db, 'Recomendations', id);
        const docInfo = await getDoc(q);
        const data = docInfo.data();
        setSelectCategory(data.categoryId);
        setNewProductAsin(data.asin);
        setNewProductName(data.title);
        setPhotoPreview(data.image);
        setNewProductWeight(String(data.weightInLbs));
        setPrice(String(data.price));
        setReviewsNumber(String(data.reviewsNumber));
        setStarProduct(String(data.startProduct));
        setStatusProduct(data.status);
        setInfoRecomendation(data);
      } catch {
        CustomToast('error', 'No se pudo cargar la información del producto');
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecomendation();
  }, [id]);

  const isValidateForm = useMemo(() => {
    if (!selectCategory) return false;
    if (!photoPreview) return false;
    if (!newProductAsin.trim()) return false;
    if (!newProductName.trim()) return false;
    if (!newProductWeight) return false;
    if (!price) return false;
    if (!reviewsNumber) return false;
    if (!startProduct) return false;

    return true;
  }, [
    selectCategory,
    photoProduct,
    newProductAsin,
    newProductName,
    newProductWeight,
    price,
    reviewsNumber,
    startProduct,
  ]);

  const addProduct = async () => {
    Swal.fire({
      text: '¿Estas segur@ que quieres Actualizar este producto?',
      icon: 'question',
      showCancelButton: true,
      background: '#111111',
      confirmButtonColor: '#FFD000',
      cancelButtonColor: '#555555',
      confirmButtonText: 'Si, Actualizar',
      cancelButtonText: 'No, cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        let urlPhoto = infoRecomendation?.image || '';
        try {
          const uidPhoto = generateUID();
          const storage = getStorage();
          if (photoProduct) {
            const storageRef = ref(
              storage,
              `products/${uidPhoto}-${photoProduct?.name}`,
            );

            await uploadBytes(storageRef, photoProduct).then(async () => {
              urlPhoto = await getDownloadURL(storageRef);
            });
          }
          const findCategory = listCategory.find(
            (category) => category.id === selectCategory,
          );
          if (!findCategory) return;

          const newProduct = {
            asin: newProductAsin,
            title: newProductName,
            weightInLbs: parseFloat(newProductWeight),
            image: urlPhoto,
            price: parseFloat(price),
            reviewsNumber: parseFloat(reviewsNumber),
            startProduct: parseFloat(startProduct),
            categoryId: findCategory.id,
            nameCategory: findCategory.name,
            source: 'local',
            status: statusProduct,
          };
          await updateDoc(doc(db, 'Recomendations', id), newProduct);
          navigate(-1);
        } catch {
          CustomToast('error', 'No se pudo actualizar el producto');
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const onChangePhoto = async (file) => {
    if (!file) {
      setPhotoProduct(null);
      setPhotoPreview(infoRecomendation?.image || '');

      return;
    }
    const resPreview = await getBase64(file);

    setPhotoProduct(file);
    setPhotoPreview(resPreview);
  };

  if (isLoading) return <PageLoader />;

  return (
    <div id="accountPage" className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Editando producto
      </h2>
      <div>
        <p className="label">Categoria</p>
        <Input
          required
          type="select"
          className="mainInput"
          placeholder="El status del pedido"
          onChange={(e) => setSelectCategory(e.target.value)}
          value={selectCategory}
        >
          {listCategory.map((item, index) => {
            return (
              <option value={item.id} key={index}>
                {item.name}
              </option>
            );
          })}
        </Input>
        <p className="label">ASIN</p>
        <Input
          required
          type="text"
          className="mainInput"
          value={newProductAsin}
          onChange={(e) => setNewProductAsin(e.target.value)}
          placeholder="El ASIN del Producto"
        />
        <p className="label">Nombre</p>
        <Input
          required
          type="text"
          className="mainInput"
          value={newProductName}
          onChange={(e) => setNewProductName(e.target.value)}
          placeholder="El nombre del nuevo producto"
        />
        <div className="mb-3" style={{ color: '#FFF' }}>
          <label htmlFor="photoProduct" className="form-label">
            Foto del Producto
          </label>
          <input
            style={{ background: 'none', color: '#FFF' }}
            className="form-control"
            type="file"
            id="photoProduct"
            onChange={(e) => onChangePhoto(e.target.files?.[0] || null)}
            accept="image/*"
          />
          {photoPreview && (
            <img
              src={photoPreview}
              alt="Foto del Producto"
              width={250}
              height={200}
              style={{ objectFit: 'cover', marginTop: '10px' }}
            />
          )}
        </div>
        <p className="label">Peso (lb)</p>
        <Input
          required
          type="number"
          className="mainInput"
          value={newProductWeight}
          onChange={(e) => setNewProductWeight(e.target.value)}
          placeholder="El peso del nuevo producto"
        />
        <p className="label">Precio (Todo incluido)</p>
        <Input
          required
          type="number"
          className="mainInput"
          placeholder="El precio final"
          onChange={(e) => setPrice(e.target.value)}
          value={price}
        />
        <p className="label">Cantidad de Reviews</p>
        <Input
          required
          type="number"
          className="mainInput"
          placeholder="Cantidad de Reviews"
          onChange={(e) => setReviewsNumber(e.target.value)}
          value={reviewsNumber}
        />
        <p className="label">Número de Estrellas</p>
        <Input
          required
          type="number"
          className="mainInput"
          placeholder="Número de Estrellas"
          onChange={(e) => setStarProduct(e.target.value)}
          value={startProduct}
        />
        <div className="form-check">
          <input
            className="form-check-input text-white"
            type="checkbox"
            value=""
            id="flexCheckChecked"
            checked={statusProduct}
            onChange={(e) => setStatusProduct(e.target.checked)}
          />
          <label
            className="form-check-label text-white"
            htmlFor="flexCheckChecked"
          >
            Producto Activo
          </label>
        </div>
        <Button
          onClick={addProduct}
          className="primaryBtn"
          style={{ marginTop: '0.5rem' }}
          disabled={!isValidateForm}
        >
          Actualizar
        </Button>
      </div>
    </div>
  );
}
