import { useState, useEffect } from 'react';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { MAX_PRODUCTS_PER_PAGE } from '../../../contants';
import { cancelAllRequests } from '../../../util/axios-req';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import TextLoader from '../../../components/Loaders/TextLoader/TextLoader';
import { AmazonProductCard } from '../../../components/Products/Card/Amazon/AmazonProductCard';
import BannerCarousel from '../../../components/BannerCarousel/BannerCarousel';
import AmazonService from '../../../services/amazon.service';
import FirebaseService from '../../../services/firebase.service';

export function DashboardPage() {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [trips, setTrips] = useState([]);
  const [productHistory, setProductHistory] = useState([]);
  const [pageLimit, setPageLimit] = useState(MAX_PRODUCTS_PER_PAGE);

  const amazonService = AmazonService.getInstance();
  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    const fetchData = async () => {
      if (loading) return;
      try {
        resetValues();
        setLoading(true);
        const productPromise = searchDeals();
        const driversPromise = searchDrivers();
        const tripsPromise = searchTrips();
        const productHistory = searchProductHistory();
        await Promise.all([
          productPromise,
          driversPromise,
          tripsPromise,
          productHistory,
        ]);
      } catch {
        CustomToast('error', 'Error al buscart ofertas');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const resetValues = async () => {
    cancelAllRequests();
    setSearchResults([]);
    setPageLimit(MAX_PRODUCTS_PER_PAGE);
  };

  const searchDeals = async () => {
    try {
      const response = await amazonService.deals();
      let results = response?.results;
      if (results?.length > 0) {
        results = results.map((item) => {
          return {
            ...item,
            image: item?.image,
          };
        });
        setSearchResults((prev) => [...prev, ...results]);
      }
    } catch {
      CustomToast('error', 'Error al buscar ofertas');
    }
  };

  const searchDrivers = async () => {
    try {
      const response = await firebaseService.findDrivers();
      if (response.length > 0) setDrivers(response);
    } catch {
      CustomToast('error', 'Error al buscar conductores');
    }
  };

  const searchTrips = async () => {
    try {
      const response = await firebaseService.findTrips();
      if (response.length > 0) setTrips(response);
    } catch {
      CustomToast('error', 'Error al buscar viajes');
    }
  };

  const searchProductHistory = async () => {
    try {
      const response = await firebaseService.findHistoryProducts();
      if (response.length > 0) setProductHistory(response);
    } catch {
      CustomToast('error', 'Error al buscar historial de productos');
    }
  };

  const addMoreProducts = () => {
    setPageLimit((prev) => prev + MAX_PRODUCTS_PER_PAGE);
  };

  if (loading)
    return (
      <div className="loadingPage">
        <TextLoader
          texts={[
            'Buscando en nuestra base de datos',
            'Definiendo precios todo incluido',
            'Calculando fecha de entrega',
            'Ya casi terminamos',
            'Personalizando tu página de productos',
          ]}
        />
      </div>
    );

  const productCardComponents = {
    amazon: (item, index) => (
      <AmazonProductCard
        key={index}
        productDefault={item}
        trips={trips}
        drivers={drivers}
        productHistory={productHistory}
      />
    ),
  };

  return (
    <>
      <BannerCarousel />
      <h1
        className="text-center"
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        Ofertas del día
      </h1>
      <div id="productGrid">
        {searchResults
          .slice(0, pageLimit)
          .map((item, index) =>
            productCardComponents[item?.source]
              ? productCardComponents[item?.source](item, index)
              : null,
          )}
      </div>
      {searchResults?.length === 0 ? (
        <p>No se encontraron resultados.</p>
      ) : (
        <>
          {searchResults.length > pageLimit && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '15px',
              }}
            >
              <button className="btn btn-warning" onClick={addMoreProducts}>
                Ver más
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
}
