import './App.css';
import './assets/css/CustomTable.css';
import './assets/css/Pagination.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '@fontsource/roboto';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MainLayout } from './layouts/MainLayout/MainLayout';
import { AdminPage } from './pages/admin/AdminPage/AdminPage';
import { AdminEditUserPage } from './pages/admin/AdminEditUserPage/AdminEditUserPage';
import { AdminEditServicePage } from './pages/admin/AdminEditServicePage/AdminEditServicePage';
import { AdminEditProductPage } from './pages/admin/AdminEditProductPage/AdminEditProductPage';
import { AdminCreateOrderPage } from './pages/admin/AdminCreateOrderPage/AdminCreateOrderPage';
import { AdminEditOrderPage } from './pages/admin/AdminEditOrderPage/AdminEditOrderPage';
import { AdminCreateTravelerPage } from './pages/admin/AdminCreateTravelerPage/AdminCreateTravelerPage';
import { AdminEditDriverPage } from './pages/admin/AdminEditDriverPage/SingleDriverPage';
import { AdminCreateRecomendationPage } from './pages/admin/AdminCreateRecomendationPage/AdminCreateRecomendationPage';
import { AdminEditRecomendationPage } from './pages/admin/AdminEditRecomendationPage/AdminEditRecomendationPage';
import { AdminCreateDiscountCodePage } from './pages/admin/AdminCreateDiscountCodePage/AdminCreateDiscountCodePage';
import { AdminEditDiscountCodePage } from './pages/admin/AdminEditDiscountCodePage/AdminEditDiscountCodePage';
import { AdminCreateCategoryPage } from './pages/admin/AdminCreateCategoryPage/AdminCreateCategoryPage';
import { AdminEditCategoryPage } from './pages/admin/AdminEditCategoryPage/AdminEditCategoryPage';
import { AdminCreateCommonSearchPage } from './pages/admin/AdminCreateCommonSearchPage/AdminCreateCommonSearchPage';
import { AdminEditCommonSearchPage } from './pages/admin/AdminEditCommonSearchPage/AdminEditCommonSearchPage';
import { AccountPage } from './pages/public/AccountPage/AccountPage';
import { OrderDetailsPage } from './pages/public/OrderDetailsPage/OrderDetailsPage';
import { OrdersPage } from './pages/public/OrdersPage/OrdersPage';
import { DashboardPage } from './pages/public/DashboardPage/DashboardPage';
import { SearchPage } from './pages/public/SearchPage/SearchPage';
import { CheckoutPage } from './pages/public/CheckoutPage/CheckoutPage';
import { TermsPage } from './pages/public/TermsPage/TermsPage';
import { LoginPage } from './pages/auth/LoginPage/LoginPage';
import { RegisterPage } from './pages/auth/RegisterPage/RegisterPage';
import { ResetPasswordPage } from './pages/auth/ResetPasswordPage/ResetPasswordPage';
import { NotFound } from './pages/public/NotFound/NotFound';
import { StoreLayout } from './layouts/StoreLayout/StoreLayout';
import { AdminCreateProductPage } from './pages/admin/AdminCreateProductPage/AdminCreateProductPage';
import ProductDetailPage from './pages/public/ProductDetailPage/ProductDetailPage';
import { ProtectedRoute } from './components/Redirect/ProtectedRoute';
import { ScrollToTop } from './components/Redirect/ScrollToTop';

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* Admin Routes */}
        <Route
          element={<ProtectedRoute allowedRoles={['admin']} redirectPath="/" />}
        >
          <Route element={<MainLayout />}>
            <Route path="admin" element={<AdminPage />} />
            <Route path="user/:id" element={<AdminEditUserPage />} />
            <Route path="service/:id" element={<AdminEditServicePage />} />
            <Route path="product/create" element={<AdminCreateProductPage />} />
            <Route path="product/:id" element={<AdminEditProductPage />} />
            <Route path="order/create" element={<AdminCreateOrderPage />} />
            <Route path="order/:id" element={<AdminEditOrderPage />} />
            <Route path="driver/create" element={<AdminCreateTravelerPage />} />
            <Route path="driver/:id" element={<AdminEditDriverPage />} />
            <Route
              path="recomendation/create"
              element={<AdminCreateRecomendationPage />}
            />
            <Route
              path="recomendation/:id"
              element={<AdminEditRecomendationPage />}
            />
            <Route
              path="discount-code/create"
              element={<AdminCreateDiscountCodePage />}
            />
            <Route
              path="discount-code/:id"
              element={<AdminEditDiscountCodePage />}
            />
            <Route
              path="category/create"
              element={<AdminCreateCategoryPage />}
            />
            <Route path="category/:id" element={<AdminEditCategoryPage />} />
            <Route
              path="common-search/create"
              element={<AdminCreateCommonSearchPage />}
            />
            <Route
              path="common-search/:id"
              element={<AdminEditCommonSearchPage />}
            />
          </Route>
        </Route>
        {/* Authenticated Routes */}
        <Route element={<ProtectedRoute allowedRoles={['admin', 'user']} />}>
          <Route element={<MainLayout />}>
            <Route path="account" element={<AccountPage />} />
            <Route path="orders" element={<OrdersPage />} />
            <Route path="orders/:id" element={<OrderDetailsPage />} />
          </Route>
        </Route>
        {/* Public Routes */}
        <Route element={<MainLayout />}>
          <Route element={<StoreLayout />}>
            <Route index element={<DashboardPage />} />
            <Route path="search" element={<SearchPage />} />
            <Route path="detail/:id" element={<ProductDetailPage />} />
          </Route>
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="terms" element={<TermsPage />} />
        </Route>
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="reset" element={<ResetPasswordPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
