import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import CustomSelect from '../../../Inputs/CustomSelect/CustomSelect';
import { formatDateSpanish, parseDate } from '../../../../util/date';
import { CustomToast } from '../../../Notifications/CustomToast';
import { itemExistsInCart } from '../../../../util/cart';
import { useAuth } from '../../../../hooks/useAuth';
import FirebaseService from '../../../../services/firebase.service';
import {
  getAsinFromUrl,
  getWeightFromProductDetails,
  getWeigthFromProductDimensions,
} from '../../../../util/amazon';
import { findHistoryItem } from '../../../../util/array';
import { calculateAmazonPrice } from '../../../../util/price';
import AmazonService from '../../../../services/amazon.service';
import ModalLoader from '../../../Loaders/ModalLoader/ModalLoader';
import {
  DEFAULT_AMAZON_SHIPPING,
  MAX_PRODUCT_PRICE,
  MAX_PRODUCT_WEIGHT,
} from '../../../../contants';

const AmazonAddModal = ({
  isOpen,
  toggle,
  productDefault,
  trips = [],
  drivers = [],
  productHistory = [],
  colorOptions = [],
  sizeOptions = [],
  setProductReportModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [product, setProduct] = useState(null);
  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);
  const [travelerSelect, setTravelerSelect] = useState(null);
  const [weightProduct, setWeigthProduct] = useState(null);

  const { profile, cart, setCart } = useAuth();
  const amazonService = AmazonService.getInstance();
  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    if (product === null && productDefault) {
      setProduct(productDefault);
      if (colorOptions?.length > 0) {
        const defaultColor = colorOptions.find(
          (item) =>
            item?.is_selected === true ||
            item?.asin === productDefault.asin ||
            getAsinFromUrl(item?.url ?? '') === productDefault.asin,
        );
        if (defaultColor)
          setColor({
            value: productDefault?.asin,
            label: defaultColor.value,
          });
      }
      if (sizeOptions?.length > 0) {
        const defaultSize = sizeOptions.find(
          (item) =>
            item?.is_selected === true ||
            item?.asin === productDefault.asin ||
            getAsinFromUrl(item?.url ?? '') === productDefault.asin,
        );
        if (defaultSize)
          setSize({
            value: productDefault.asin,
            label: defaultSize.value,
          });
      }
    }
  }, [product, productDefault, sizeOptions, colorOptions]);

  useEffect(() => {
    const fetchData = async () => {
      if (!color || color?.value === product?.asin) return;
      try {
        setLoading(true);
        const searchResponse = await amazonService.search(color.value);
        const detailResponse = await amazonService.detail(color.value);
        setProduct(
          {
            ...(searchResponse.results.find(
              (item) => item.asin === color.value,
            ) ?? {
              asin: size.value,
              image:
                detailResponse?.images?.length > 0
                  ? detailResponse.images[0]
                  : null,
            }),
            ...detailResponse,
          } ?? null,
        );
      } catch {
        return null;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [color]);

  useEffect(() => {
    const fetchData = async () => {
      if (!size || size?.value === product?.asin) return;
      try {
        setLoading(true);
        const searchResponse = await amazonService.search(size.value);
        const detailResponse = await amazonService.detail(size.value);
        setProduct(
          {
            ...(searchResponse.results.find(
              (item) => item.asin === size.value,
            ) ?? {
              asin: size.value,
              image:
                detailResponse.product_results.images.length > 0
                  ? detailResponse.product_results.images[0]
                  : null,
            }),
            ...detailResponse.product_results,
          } ?? null,
        );
      } catch {
        return null;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [size]);

  const salePrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = product?.asin;
    let price = parseFloat(product?.price?.replace(/[^\d.-]+/g, '')) || 0;
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let weightRecord = historyItem?.currentWeigth ?? null;
    let historyPrice = historyItem?.price;
    if (
      (!price && !historyPrice) ||
      (price && parseFloat(price) > MAX_PRODUCT_PRICE) ||
      (historyPrice && parseFloat(historyPrice) > MAX_PRODUCT_PRICE)
    )
      return null;
    if (historyPrice) price = parseFloat(historyPrice);
    let weightAmazon = getWeightFromProductDetails(product);
    const shippingAmazon = 0;
    let weightDimension = getWeigthFromProductDimensions(product);
    if (weightRecord) {
      if (parseFloat(weightRecord) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightRecord) < 0.25) weightRecord = 0.25;
      setWeigthProduct(parseFloat(weightRecord));
      return calculateAmazonPrice(
        price,
        parseFloat(weightRecord) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightAmazon) {
      if (parseFloat(weightAmazon) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightAmazon) < 0.25) weightAmazon = 0.25;
      setWeigthProduct(parseFloat(weightAmazon));
      return calculateAmazonPrice(
        price,
        parseFloat(weightAmazon) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightDimension) {
      if (parseFloat(weightDimension) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightDimension) < 0.25) weightDimension = 0.25;
      setWeigthProduct(parseFloat(weightDimension));
      return calculateAmazonPrice(
        price,
        parseFloat(weightDimension) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    return calculateAmazonPrice(price, null, shippingAmazon ?? 0);
  }, [product, productHistory]);

  const originalPrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = product?.asin;
    let price = !isNaN(
      parseFloat(product?.price_string?.replace(/[^\d.-]+/g, '')),
    )
      ? parseFloat(product?.price_string?.replace(/[^\d.-]+/g, ''))
      : parseFloat(product?.price?.replace(/[^\d.-]+/g, '')) || 0;
    if (!price) return null;
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let weightRecord = historyItem?.currentWeigth ?? null;
    let weightAmazon = getWeightFromProductDetails(product);
    const shippingAmazon = 0;
    let weightDimension = getWeigthFromProductDimensions(product);
    if (weightRecord) {
      if (parseFloat(weightRecord) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightRecord) < 0.25) weightRecord = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightRecord) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightAmazon) {
      if (parseFloat(weightAmazon) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightAmazon) < 0.25) weightAmazon = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightAmazon) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightDimension) {
      if (parseFloat(weightDimension) > MAX_PRODUCT_WEIGHT) return null;
      if (parseFloat(weightDimension) < 0.25) weightDimension = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightDimension) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    return calculateAmazonPrice(price, null, shippingAmazon ?? 0);
  }, [product, productHistory]);

  const estimateDeliveryDate = useMemo(() => {
    const now = new Date();
    const dateKey =
      product?.prime_shipping_info ?? product?.shipping_info ?? null;
    const dateAmazon = dateKey ? parseDate(dateKey) : null;
    if (!dateAmazon) {
      now.setDate(now.getDate() + DEFAULT_AMAZON_SHIPPING);
      return now;
    }
    const filterTrips = trips.filter(
      (trip) => new Date(trip?.date) > dateAmazon,
    );
    const dateOptimization = filterTrips.find((trip) => {
      const driver = drivers.find((user) => user?.uid === trip.travelerUid);
      if (!driver) return false;
      const dayBeforeTravel = parseInt(driver.dayBeforeTravel) ?? 3;
      const travelDate = new Date(trip.date);
      travelDate.setDate(travelDate.getDate() - dayBeforeTravel);
      return dateAmazon < travelDate;
    });
    if (!dateOptimization) {
      const fallbackDate = new Date(dateAmazon);
      fallbackDate.setDate(fallbackDate.getDate() + DEFAULT_AMAZON_SHIPPING);
      return fallbackDate;
    }
    setTravelerSelect(dateOptimization);
    const dateTravel = new Date(dateOptimization.date);
    const daysAfterTravel =
      parseInt(
        drivers.find((user) => user?.uid === dateOptimization.travelerUid)
          ?.dayAfterTravel,
      ) ?? 3;
    dateTravel.setDate(dateTravel.getDate() + daysAfterTravel);
    return dateTravel;
  }, [product, trips, drivers]);

  const handleAddToCart = async () => {
    if (!product) return;
    try {
      setLoadingCart(true);
      if (itemExistsInCart(cart.items, product)) {
        CustomToast('warning', 'Producto ya se encuentra en el carrito');
        return;
      }
      const productToAdd = {
        ...product,
        weigthAmazon: weightProduct ?? null,
        priceCalculate: salePrice,
        estimateDeliveryDate: estimateDeliveryDate?.toISOString(),
        travelerSelect,
        color,
        size,
        createdAt: Date.now(),
      };
      const items = [...cart.items, productToAdd];
      if (profile)
        await firebaseService.updateCart(profile?.uid, { ...cart, items });
      setCart({ ...cart, items });
      CustomToast('success', 'Producto agregado al carrito');
      toggle();
    } catch {
      CustomToast('error', 'Error al agregar producto al carrito');
    } finally {
      setLoadingCart(false);
    }
  };

  return (
    <Modal id="productModal" isOpen={isOpen} toggle={toggle} size="lg" centered>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          marginBottom: '1rem',
        }}
      >
        <IoClose onClick={toggle} />
      </div>
      {loading ? (
        <ModalLoader />
      ) : (
        <>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <img
              src={product?.image}
              alt="Foto"
              style={{ width: '200px', height: '200px', objectFit: 'contain' }}
            />
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
            >
              <p style={{ fontSize: '20px', fontWeight: '700' }}>
                {product?.title}
              </p>
              {estimateDeliveryDate && (
                <p className="productDeliveryDate">
                  Fecha Estimada de entrega:{' '}
                  <b>{formatDateSpanish(estimateDeliveryDate)}</b>
                </p>
              )}
              {salePrice && (
                <p className="productPrice">
                  {originalPrice > salePrice && (
                    <span className="productRRP">
                      <span className="productPriceSymbol">$</span>
                      <span className="productPriceWhole">
                        {originalPrice.toFixed(2)}
                      </span>
                    </span>
                  )}
                  <span className="productDiscountedPrice">
                    <span className="productPriceSymbol">$</span>
                    <span className="productPriceWhole">
                      {salePrice.toFixed(2)}
                    </span>
                  </span>
                </p>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              justifyContent: 'space-between',
              marginTop: '1rem',
            }}
          >
            {colorOptions && colorOptions?.length > 0 && (
              <div
                className="section-container"
                onClick={(e) => e.stopPropagation()}
              >
                <p className="section-title">Colores:</p>
                <CustomSelect
                  placeholder="Selecciona un color"
                  value={color}
                  setValue={setColor}
                  options={colorOptions.map((item) => {
                    return {
                      value: getAsinFromUrl(item?.url ?? '') ?? item?.asin,
                      label: item.value,
                    };
                  })}
                />
              </div>
            )}
            {sizeOptions && sizeOptions?.length > 0 && (
              <div
                className="section-container"
                onClick={(e) => e.stopPropagation()}
              >
                <p className="section-title">Tallas:</p>
                <CustomSelect
                  placeholder="Selecciona una talla"
                  value={size}
                  setValue={setSize}
                  options={sizeOptions.map((item) => {
                    return {
                      value: getAsinFromUrl(item?.url ?? '') ?? item?.asin,
                      label: item.value,
                    };
                  })}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '1rem',
              gap: '1rem',
            }}
          >
            <Button className="secondaryBtn" onClick={toggle}>
              Cancelar
            </Button>
            <Button
              className="primaryBtn"
              onClick={
                salePrice ? handleAddToCart : () => setProductReportModal(true)
              }
            >
              {salePrice
                ? loadingCart
                  ? 'Agregando al carrito...'
                  : 'Agregar al carrito'
                : 'Solicitar cotizacion'}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default AmazonAddModal;
