import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import PageLoader from '../Loaders/PageLoader/PageLoader';
import { Navigate, Outlet } from 'react-router-dom';

const isAuthorized = (allowedRoles, authValues) => {
  const role = authValues?.profile?.isAdmin ? 'admin' : 'user';
  if (!role) return false;
  return !!authValues?.profile && allowedRoles.includes(role);
};

export function ProtectedRoute({ allowedRoles, redirectPath = '/login' }) {
  const authValues = useAuth();
  if (authValues?.loading) return <PageLoader />;
  if (!isAuthorized(allowedRoles, authValues))
    return <Navigate replace to={redirectPath} />;
  return <Outlet />;
}
