import { useState, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { query, getDocs, collection, addDoc, where } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../../util/firebase';
import { DatePicker } from 'reactstrap-date-picker';
import { Button, Input, Modal } from 'reactstrap';
import { MdChevronLeft } from 'react-icons/md';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { IoClose } from 'react-icons/io5';
import { getBase64 } from '../../../util/photos';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { generateUID } from '../../../util/token';
import { formatDateISO } from '../../../util/date';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import { useAuth } from '../../../hooks/useAuth';
import EmailService from '../../../services/email.service';
import FirebaseService from '../../../services/firebase.service';

export function AdminCreateOrderPage() {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [newOrderCustomer, setNewOrderCustomer] = useState();
  const [newOrderDateCreated, setNewOrderDateCreated] = useState(
    new Date().toISOString(),
  );
  const [newOrderDestinationCountry, setNewOrderDestinationCountry] =
    useState('El Salvador');
  const [newOrderDestinationAddress, setNewOrderDestinationAddress] =
    useState();
  const [newOrderArrivalDate, setNewOrderArrivalDate] = useState();
  const [newOrderStatus, setNewOrderStatus] = useState('Orden Confirmada');
  const [amountCancelled, setAmountCancelled] = useState(0);
  const [isDelivery, setIsDelivery] = useState(false);
  const [comment, setComment] = useState('');
  const [weigthEstimate, setWeigthEstimate] = useState('');
  const [isWeigthEstimate, setIsWeigthEstimate] = useState(false);
  const [priceProduct, setPriceProduct] = useState(0);
  const [productNumber, setProductNumber] = useState(1);
  const [financing, setFinancing] = useState(0);
  const [priceDeliveryHome, setPriceDeliveryHome] = useState(0);
  const [nameProduct, setNameProduct] = useState('');
  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);
  const [asin, setAsin] = useState('');
  const [nameShop, setNameShop] = useState('');
  const [urlProduct, setUrlProduct] = useState('');
  const [urlTracking, setUrlTracking] = useState('');
  const [selectTravel, setSelectTravel] = useState(null);
  const [photoProduct, setPhotoProduct] = useState(null);
  const [photoPreview, setPhotoPreview] = useState('');
  const [users, setUsers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [searchedUser, setSearchedUser] = useState('');
  const [selectCustomerModalIsVisible, setSelectCustomerModalIsVisible] =
    useState();

  const { profile } = useAuth();
  const navigate = useNavigate();
  const localizer = momentLocalizer(moment);
  const firebaseService = FirebaseService.getInstance();
  const emailService = EmailService.getInstance();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!profile) return;
        setLoading(true);
        const responseUsers = await firebaseService.findUsers();
        const responseDrivers = await firebaseService.findDrivers();
        setUsers(responseUsers ?? []);
        setDrivers(responseDrivers ?? []);
      } catch {
        CustomToast('error', 'No se pudieron cargar los usuarios');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [profile]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await firebaseService.findTrips();
        setEvents(response ?? []);
      } catch {
        CustomToast('error', 'No se pudieron cargar los eventos');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  async function verifyProductAsin(asin) {
    const req = query(
      collection(db, 'historyProduct'),
      where('asin', '==', asin),
    );
    const documentos = await getDocs(req);
    if (!documentos.empty) return documentos.docs[0].id;
    return undefined;
  }

  const onSubmit = async () => {
    try {
      setLoading(true);
      let urlPhoto = '';
      if (photoProduct) {
        const uidPhoto = generateUID();
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `products/${uidPhoto}-${photoProduct?.name}`,
        );
        await uploadBytes(storageRef, photoProduct).then(async () => {
          urlPhoto = await getDownloadURL(storageRef);
        });
      }
      let uidProduct = '';
      const verifyExist = await verifyProductAsin(asin);
      if (!verifyExist) {
        const payloadProduct = {
          nameProduct: nameProduct,
          weigthEstimate: weigthEstimate,
          currentWeigth: '',
          comment: comment,
          urlPhoto: urlPhoto,
          asin: asin,
          urlProduct: urlProduct,
          category: '',
        };
        const resProduct = await addDoc(
          collection(db, 'historyProduct'),
          payloadProduct,
        );
        if (!resProduct) return;
        uidProduct = resProduct?.id;
      } else uidProduct = verifyExist ?? '';
      const newOrder = {
        uid: uuidv4(),
        customerName: `${newOrderCustomer?.firstName ?? ''} ${newOrderCustomer?.lastName ?? ''}`,
        customerUid: newOrderCustomer?.uid ?? '',
        dateCreated: newOrderDateCreated,
        arrivalDate: newOrderArrivalDate ?? '',
        deliveryAddress: {
          country: newOrderDestinationCountry ?? '',
          address: newOrderDestinationAddress?.label ?? '',
        },
        isDelivery: isDelivery,
        traveler: {
          name: selectTravel?.title.split('-')[0] ?? '',
          dateTraveled: selectTravel
            ? new Date(selectTravel.start).toISOString()
            : '',
          uidTrip: selectTravel?.uidTrip ?? '',
        },
        status: newOrderStatus,
        weigthEstimate: weigthEstimate,
        isWeigthEstimate: isWeigthEstimate,
        product: {
          nameProduct: nameProduct,
          asin: asin,
          nameShop: nameShop,
          urlProduct: urlProduct,
          urlTracking: urlTracking,
          comment: comment,
          urlPhoto: urlPhoto,
          uidProduct: uidProduct,
          color: {
            value: color?.includes('-') ? color.split('-')[1] : '',
            label: color?.includes('-') ? color.split('-')[0] : (color ?? null),
          },
          size: {
            value: size?.includes('-') ? size.split('-')[1] : '',
            label: size?.includes('-') ? size.split('-')[0] : (size ?? null),
          },
        },
        priceProduct: priceProduct,
        productNumber: productNumber,
        financing: financing,
        priceDeliveryHome: priceDeliveryHome,
        total: totalOrder,
        canceledAmout: amountCancelled,
      };
      const docRef = await addDoc(collection(db, 'Orders'), newOrder);
      await sendEmail(docRef);
      navigate(-1);
    } catch {
      CustomToast('error', 'No se pudo crear el pedido');
    } finally {
      setLoading(false);
    }
  };

  const sendEmail = async (docRef) => {
    try {
      const addressSplit =
        newOrderDestinationAddress?.label.split(',').reverse() || [];
      const payloadEmail = {
        to: newOrderCustomer?.email || '',
        template: 'order_created',
        context: {
          id: docRef.id,
          date: formatDateISO(new Date(newOrderDateCreated)),
          status: {
            trackingUrl: `https://passeioapp.com/orders/${docRef.id}`,
          },
          shipping: {
            name: `${newOrderCustomer?.firstName || ''}`,
            address: addressSplit[2] || '',
            city: '',
            state: addressSplit[1] || '',
            zip: '',
            country: newOrderDestinationCountry,
          },
          item: {
            name: nameProduct,
            quantity: productNumber,
            price: parseFloat(totalOrder),
          },
          payment: {
            subtotal: parseFloat(totalOrder),
            taxes:
              parseFloat(financing || 0) + parseFloat(priceDeliveryHome || 0),
            total: parseFloat(totalOrder),
          },
        },
      };
      await emailService.send(payloadEmail);
    } catch {
      CustomToast('error', 'No se pudo enviar el correo');
    }
  };

  const totalOrder = useMemo(() => {
    return (
      (
        parseFloat(priceProduct || 0) +
        parseFloat(financing || 0) +
        parseFloat(priceDeliveryHome || 0)
      ).toFixed(2) * productNumber
    );
  }, [priceProduct, financing, priceDeliveryHome, productNumber]);

  const dateTraveled = useMemo(() => {
    if (!selectTravel) return '';
    const date = new Date(selectTravel.start);

    const dia = date.getDate().toString().padStart(2, '0');
    const mes = (date.getMonth() + 1).toString().padStart(2, '0');
    const año = date.getFullYear();
    return `${dia}/${mes}/${año}`;
  }, [selectTravel]);

  const onChangePhoto = async (file) => {
    if (!file) {
      setPhotoProduct(null);
      setPhotoPreview('');

      return;
    }
    const resPreview = await getBase64(file);

    setPhotoProduct(file);
    setPhotoPreview(resPreview);
  };

  const toggleCalendarEventModal = (event) => {
    setSelectTravel(event);
    setSelectDriverModalIsVisible(false);
  };

  const toggleSelectCustomerModal = () => {
    setSelectCustomerModalIsVisible(!selectCustomerModalIsVisible);
  };

  const listUser = useMemo(() => {
    const searchedLower = searchedUser?.toLowerCase();

    return users.filter((user) => {
      const firstName = user?.firstName || '';
      const lastName = user?.lastName || '';
      const email = user?.email || '';
      return (
        firstName.toLowerCase().includes(searchedLower) ||
        lastName.toLowerCase().includes(searchedLower) ||
        email.toLowerCase().includes(searchedLower)
      );
    });
  }, [users, searchedUser]);

  const everyEvents = useMemo(() => {
    const eventsList = [...events];
    drivers.map((value) => {
      if (value.lastDateProduct && value.deliveryProduct) {
        eventsList.push({
          title: `${value.firstName} ${value.lastName} - Ultimo dia para recibi pedidos`,
          start: moment(value.lastDateProduct).toDate(),
          end: moment(value.lastDateProduct).toDate(),
        });
        eventsList.push({
          title: `${value.firstName} ${value.lastName} - Entrega del Producto`,
          start: moment(value.deliveryProduct).toDate(),
          end: moment(value.deliveryProduct).toDate(),
        });
      }

      return value;
    });

    return eventsList;
  }, [drivers, events]);

  const selectNewOrderCustomer = (customer) => {
    setNewOrderCustomer(customer);
    toggleSelectCustomerModal();
  };

  const removeSelectedCustomer = () => {
    setNewOrderCustomer();
    toggleSelectCustomerModal();
  };

  const [selectDriverModalIsVisible, setSelectDriverModalIsVisible] =
    useState();
  const toggleSelectDriverModal = () => {
    setSelectDriverModalIsVisible(!selectDriverModalIsVisible);
  };

  if (loading) return <PageLoader />;

  return (
    <div id="accountPage" className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Crear pedido
      </h2>
      <form onSubmit={onSubmit}>
        <p className="label">Cliente</p>
        <Button
          onClick={
            newOrderCustomer
              ? removeSelectedCustomer
              : toggleSelectCustomerModal
          }
          className="primaryBtn"
          style={{ marginBottom: '0.5rem' }}
        >
          {newOrderCustomer
            ? `${newOrderCustomer.firstName} ${newOrderCustomer.lastName}`
            : 'Seleccionar cliente'}
        </Button>
        <Modal
          id="selectCustomerModal"
          isOpen={selectCustomerModalIsVisible}
          toggle={toggleSelectCustomerModal}
          size="lg"
          centered
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              marginBottom: '0.25rem',
            }}
          >
            <IoClose onClick={toggleSelectCustomerModal} />
          </div>
          <div className="table-container">
            <div>
              <p className="cardHeader">Seleccionar cliente</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '0.25rem',
                }}
              >
                <Input
                  type="text"
                  className="mainInput"
                  placeholder="Buscar Usuario"
                  style={{ marginBottom: 0 }}
                  onChange={(e) => setSearchedUser(e.target.value)}
                />
              </div>
              {listUser?.length > 0 ? (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Correo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listUser?.map((item, index) => (
                        <tr
                          key={index}
                          onClick={() => selectNewOrderCustomer(item)}
                        >
                          <td data-title="Nombre">
                            {item?.firstName + ' ' + item?.lastName}
                          </td>
                          <td data-title="Correo">{item?.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p style={{ textAlign: 'center' }}>
                  No se han encontrado usuarios
                </p>
              )}
            </div>
          </div>
        </Modal>
        <p className="label">Fecha creada</p>
        <DatePicker
          id="example-datepicker-2"
          className="mainInput"
          style={{ borderRadius: 10 }}
          showClearButton={false}
          value={newOrderDateCreated}
          onChange={(v) => setNewOrderDateCreated(v)}
          dateFormat="DD.MM.YYYY"
          placeholder="La fecha creada del pedido"
        />
        <p className="label">Fecha de entrega</p>
        <DatePicker
          id="example-datepicker"
          className="mainInput"
          style={{ borderRadius: 10 }}
          showClearButton={false}
          value={newOrderArrivalDate}
          onChange={(v) => setNewOrderArrivalDate(v)}
          dateFormat="DD.MM.YYYY"
          placeholder="La fecha de entrega del pedido"
        />
        <div style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
          <p style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Destino</p>

          <p className="label">Pais de destino</p>
          <Input
            type="text"
            className="mainInput"
            value={newOrderDestinationCountry}
            onChange={(e) => setNewOrderDestinationCountry(e.target.value)}
            placeholder="El pais de destino del pedido"
          />

          <p className="label">Dirección de destino</p>
          <GooglePlacesAutocomplete
            className="mainInput"
            selectProps={{
              newOrderDestinationAddress,
              onChange: setNewOrderDestinationAddress,
              value: newOrderDestinationAddress,
            }}
            autocompletionRequest={{
              componentRestrictions: {
                country: ['sv'],
              },
            }}
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          />

          <div className="form-check" style={{ marginTop: '10px' }}>
            <input
              className="form-check-input"
              type="checkbox"
              checked={isDelivery}
              onChange={(e) => setIsDelivery(e.target.checked)}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label label"
              htmlFor="flexCheckDefault"
              style={{ color: '#FFF' }}
            >
              Delivery Incluido
            </label>
          </div>
        </div>
        <p className="label">Viajero</p>
        <Button
          onClick={toggleSelectDriverModal}
          className="primaryBtn"
          style={{ marginBottom: '0.5rem' }}
        >
          {selectTravel
            ? `${selectTravel.title} - ${dateTraveled}`
            : 'Seleccionar viajero'}
        </Button>
        <Modal
          id="selectDriverModal"
          isOpen={selectDriverModalIsVisible}
          toggle={toggleSelectDriverModal}
          size="lg"
          centered
        >
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
              }}
            >
              <IoClose onClick={toggleSelectDriverModal} />
            </div>
            <div>
              <p className="cardHeader">Seleccionar viajero</p>
              <div>
                <Calendar
                  localizer={localizer}
                  events={everyEvents}
                  defaultDate={new Date()}
                  defaultView="month"
                  views={['month', 'agenda']}
                  messages={{
                    next: 'Sig.',
                    previous: 'Ant.',
                    today: 'Hoy',
                    month: 'Mes',
                    week: 'Semana',
                    day: 'Día',
                    date: 'Fecha',
                    time: 'Hora',
                    event: 'Evento',
                    noEventsInRange: 'No hay eventos en este rango',
                  }}
                  style={{ height: 500 }}
                  onSelectEvent={toggleCalendarEventModal}
                />
              </div>
            </div>
          </div>
        </Modal>
        <p className="label">Status</p>
        <Input
          type="select"
          className="mainInput"
          value={newOrderStatus}
          onChange={(e) => setNewOrderStatus(e.target.value)}
          placeholder="El status del pedido"
        >
          <option value="Cancelado">Cancelado</option>
          <option value="Orden Confirmada">Orden Confirmada</option>
          <option value="Producto Comprado">Producto Comprado</option>
          <option value="Producto en Camino">Producto en Camino</option>
          <option value="Recibido en Sucursal">Recibido en Sucursal</option>
          <option value="Delivery o Listo para ser retirado">
            Listo para ser Entregado o Retirado
          </option>
          <option value="Entregado">Entregado</option>
        </Input>

        <p className="label">Peso del Pedido</p>
        <Input
          type="text"
          className="mainInput"
          value={weigthEstimate}
          onChange={(e) => setWeigthEstimate(e.target.value)}
          placeholder="Peso del Pedido"
        />
        <div className="form-check" style={{ marginTop: '10px' }}>
          <input
            className="form-check-input"
            type="checkbox"
            checked={isWeigthEstimate}
            onChange={(e) => setIsWeigthEstimate(e.target.checked)}
            id="weigthEstimate"
          />
          <label
            className="form-check-label label"
            htmlFor="weigthEstimate"
            style={{ color: '#FFF' }}
          >
            Es un peso Estimado
          </label>
        </div>

        <div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
          <p style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Producto</p>
          <p className="label">Nombre del Producto</p>
          <Input
            type="text"
            className="mainInput"
            value={nameProduct}
            onChange={(e) => setNameProduct(e.target.value)}
            placeholder="Nombre del Producto"
          />
          <p className="label">ASIN</p>
          <Input
            type="text"
            className="mainInput"
            value={asin}
            onChange={(e) => setAsin(e.target.value)}
            placeholder="ASIN"
          />
          <p className="label">Color</p>
          <Input
            type="text"
            className="mainInput"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            placeholder="Color"
          />
          <p className="label">Talla</p>
          <Input
            type="text"
            className="mainInput"
            value={size}
            onChange={(e) => setSize(e.target.value)}
            placeholder="Talla"
          />
          <p className="label">Nombre de la Tienda</p>
          <Input
            type="text"
            className="mainInput"
            value={nameShop}
            onChange={(e) => setNameShop(e.target.value)}
            placeholder="Nombre de la tienda"
          />
          <p className="label">Link del producto</p>
          <Input
            type="text"
            className="mainInput"
            value={urlProduct}
            onChange={(e) => setUrlProduct(e.target.value)}
            placeholder="Link del producto"
          />
          <p className="label">Link del Tracking de la tienda</p>
          <Input
            type="text"
            className="mainInput"
            value={urlTracking}
            onChange={(e) => setUrlTracking(e.target.value)}
            placeholder="Link del Tracking"
          />
          <div className="mb-3">
            <label
              htmlFor="comment"
              className="form-label"
              style={{ color: '#FFF' }}
            >
              Comentarios
            </label>
            <textarea
              className="form-control"
              id="comment"
              rows="3"
              style={{ background: 'none', color: '#FFF' }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-3" style={{ color: '#FFF' }}>
            <label htmlFor="photoProduct" className="form-label">
              Foto del Producto
            </label>
            <input
              style={{ background: 'none', color: '#FFF' }}
              className="form-control"
              type="file"
              id="photoProduct"
              onChange={(e) => onChangePhoto(e.target.files?.[0] || null)}
              accept="image/*"
            />
            {photoPreview && (
              <img
                src={photoPreview}
                alt="Foto del Producto"
                width={250}
                height={200}
                style={{ objectFit: 'cover', marginTop: '10px' }}
              />
            )}
          </div>
        </div>
        <div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
          <p style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Costos</p>
          <p className="label">Precio del Producto</p>
          <Input
            type="number"
            className="mainInput"
            value={priceProduct}
            onChange={(e) => setPriceProduct(e.target.value)}
            placeholder="Precio del Producto"
          />
          <p className="label">Cantidad del Producto</p>
          <Input
            type="number"
            className="mainInput"
            value={productNumber}
            onChange={(e) => setProductNumber(e.target.value)}
            placeholder="Cantidad del Producto"
          />
          <p className="label">Financing</p>
          <Input
            type="number"
            className="mainInput"
            value={financing}
            onChange={(e) => setFinancing(e.target.value)}
            placeholder="Financing"
          />
          <p className="label">Home Delivery</p>
          <Input
            type="number"
            className="mainInput"
            value={priceDeliveryHome}
            onChange={(e) => setPriceDeliveryHome(e.target.value)}
            placeholder="Precio del Home Delivery"
          />
          <p className="label">Total</p>
          <Input
            type="number"
            className="mainInput"
            value={totalOrder}
            placeholder="El costo total del pedido"
            disabled
          />
          <p className="label">Monto Cancelado</p>
          <Input
            type="number"
            className="mainInput"
            value={amountCancelled}
            onChange={(e) => setAmountCancelled(e.target.value)}
            placeholder="Monto Cancelado"
          />
        </div>

        <Button type="submit" className="primaryBtn">
          Guardar
        </Button>
        <Button
          onClick={() => navigate(-1)}
          className="secondaryBtn"
          style={{ marginTop: '0.5rem' }}
        >
          Cancelar
        </Button>
      </form>
    </div>
  );
}
