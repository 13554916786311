import React, { createContext, useState, useEffect } from 'react';
import { auth } from '../util/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import PageLoader from '../components/Loaders/PageLoader/PageLoader';
import { DEFAULT_CART } from '../contants';
import { signOut } from 'firebase/auth';
import { mergeCartItems } from '../util/cart';
import FirebaseService from '../services/firebase.service';
import {
  getCartFromLocalStorage,
  removeUserFromLocalStorage,
  setCartToLocalStorage,
} from '../util/storage';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [profile, setProfile] = useState(null);
  const [cart, setCart] = useState(DEFAULT_CART);
  const [checkout, setCheckout] = useState(false);
  const [user] = useAuthState(auth);

  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    const localCart = getCartFromLocalStorage() ?? DEFAULT_CART;
    const uid = user?.uid ?? localCart?.ownerUid ?? null;
    setCart(localCart);
    const fetchProfileAndSyncCart = async () => {
      try {
        if (!uid || profile) return;
        setLoading(true);
        const [cartResponse] = await firebaseService.findOrCreate(
          'PasseioCarts',
          { ownerUid: uid },
          {
            ...DEFAULT_CART,
            ownerUid: uid,
          },
        );
        await synchronizeCartItems(cartResponse, localCart);
        const user = await firebaseService.findOne('Users', { uid });
        setProfile(user);
        setAuthenticated(true);
      } catch {
        logOut();
      } finally {
        setLoading(false);
      }
    };
    fetchProfileAndSyncCart();
  }, [user]);

  useEffect(() => {
    if (!profile || !authenticated) return;
    const unsubscribe = firebaseService.watch(
      'Users',
      { uid: profile.uid },
      setProfile,
    );
    return () => unsubscribe();
  }, [authenticated]);

  useEffect(() => {
    if (!profile || !authenticated) return;
    const unsubscribe = firebaseService.watch(
      'PasseioCarts',
      { ownerUid: profile.uid },
      setCart,
    );
    return () => unsubscribe();
  }, [authenticated]);

  useEffect(() => {
    setCartToLocalStorage(cart);
  }, [cart]);

  const logOut = async () => {
    await signOut(auth);
    removeUserFromLocalStorage();
    setProfile(null);
    setAuthenticated(false);
    setCart(DEFAULT_CART);
  };

  const synchronizeCartItems = async (cart, localCart) => {
    let newCart = cart;
    if (JSON.stringify(cart) !== JSON.stringify(localCart)) {
      newCart = {
        ...cart,
        items: mergeCartItems(cart?.items, localCart?.items),
      };
      await firebaseService.update(
        'PasseioCarts',
        { ownerUid: newCart.ownerUid },
        newCart,
      );
    }
    setCart(newCart);
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        profile,
        cart,
        setCart,
        checkout,
        setCheckout,
        logOut,
      }}
    >
      {loading ? <PageLoader /> : children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
