import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  query,
  collection,
  getDocs,
  where,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../../../util/firebase';
import { MdChevronLeft } from 'react-icons/md';
import { Input, Button } from 'reactstrap';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { CustomToast } from '../../../components/Notifications/CustomToast';

export function AdminEditUserPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [birthdate, setBirthdate] = useState(null);
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [usedLoyaltyPoints, setUsedLoyaltyPoints] = useState(0);
  const [isTraveler, setIsTraveler] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (!id) return;
      try {
        setIsLoading(true);
        const q = query(collection(db, 'Users'), where('uid', '==', id));
        const doc = await getDocs(q);
        const data = doc.docs[0].data();
        setUserId(data.uid ?? data.uid);
        setName(`${data.firstName} ${data.lastName}`);
        setEmail(data.email);
        setPhone(data.phone ?? null);
        setBirthdate(data.birthdate ?? null);
        setLoyaltyPoints(data.loyaltyPoints ?? 0);
        setUsedLoyaltyPoints(data.usedLoyaltyPoints ?? 0);
        setIsTraveler(data.type === 'driver');
      } catch {
        CustomToast('error', 'No se pudo cargar la información del usuario');
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserData();
  }, [id]);

  const saveChanges = async () => {
    try {
      setIsLoading(true);
      const userRef = query(collection(db, 'Users'), where('uid', '==', id));
      const findUsers = await getDocs(userRef);
      findUsers.forEach(async (user) => {
        const getUser = doc(db, 'Users', user?.id);
        await updateDoc(getUser, {
          phone,
          birthdate,
          loyaltyPoints,
          type: isTraveler ? 'driver' : 'customer',
        });
      });
      CustomToast('success', 'Usuario actualizado correctamente');
      navigate(-1);
    } catch {
      CustomToast('error', 'No se pudo guardar los cambios');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <PageLoader />;

  return (
    <div id="accountPage" className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Usuario
      </h2>
      <p className="label">ID</p>
      <Input
        type="text"
        className="mainInput"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
        placeholder="El ID del usuario"
        disabled
      />
      <p className="label">Nombre</p>
      <Input
        type="text"
        className="mainInput"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="El nombre del usuario"
        disabled
      />
      <p className="label">Correo</p>
      <Input
        type="text"
        className="mainInput"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="El correo del usuario"
        disabled
      />
      <p className="label">Teléfono</p>
      <Input
        required
        type="text"
        className="mainInput"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        placeholder="El teléfono del usuario"
      />
      <p className="label">Fecha de nacimiento</p>
      <Input
        type="date"
        className="mainInput"
        placeholder="Fecha de nacimiento"
        value={birthdate}
        onChange={(e) => setBirthdate(e.target.value)}
      />

      <p className="label">Puntos de lealtad</p>
      <Input
        type="number"
        className="mainInput"
        value={loyaltyPoints}
        placeholder="0"
        min={0}
        onChange={(e) => setLoyaltyPoints(e.target.value)}
        step={1}
      />

      <p className="label">Puntos de lealtad usados</p>
      <Input
        type="number"
        className="mainInput"
        value={usedLoyaltyPoints}
        placeholder="0"
        disabled
      />

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          checked={isTraveler}
          onChange={(e) => setIsTraveler(e.target.checked)}
        />
        <label
          className="form-check-label text-white"
          htmlFor="flexCheckDefault"
        >
          Es Viajero
        </label>
      </div>

      <Button
        onClick={saveChanges}
        className="primaryBtn"
        style={{ marginTop: '0.25rem' }}
      >
        Guardar
      </Button>
    </div>
  );
}
