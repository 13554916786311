import { PiTrashSimpleBold } from 'react-icons/pi';
import { formatDateSpanish } from '../../../../util/date';
import { useMemo } from 'react';
import { FaEye, FaMinus, FaPlus } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { Tooltip } from 'react-tooltip';

const MODE_CHANGE = {
  add: 'add',
  less: 'less',
};

export const SheinCheckoutCard = ({
  product,
  removeItemFromCart,
  changeNumber,
  toggleCommentModal,
}) => {
  const totalProduct = useMemo(() => {
    const quantity = product?.quantity ?? 1;
    const priceNormal = product?.priceCalculate;
    return parseFloat(priceNormal) * quantity;
  }, [product]);

  const handleChange = (mode) => {
    const quantity = product?.quantity ?? 1;
    if (mode === MODE_CHANGE.add) {
      changeNumber(product?.goods_id, quantity + 1);
      return;
    }
    if (mode === MODE_CHANGE.less) {
      if (quantity === 1) return;
      changeNumber(product?.goods_id, quantity - 1);
    }
  };

  const handleOpenComments = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleCommentModal({
      id: product?.goods_id,
      comment: product?.comment,
    });
  };

  return (
    <div className="cartItem">
      <div className="checkoutProductImageContainer">
        <img
          alt="checkoutProductImage"
          src={product?.goods_img}
          className="checkoutProductImage"
        />
      </div>
      <div className="cartItemContainer">
        <div className="cartItemHeader">
          <p className="cartItemName">{product?.goods_name}</p>
          <PiTrashSimpleBold
            onClick={() => removeItemFromCart(product)}
            className="removeCartItemIcon"
          />
          <p className="cartItemPrice">${totalProduct?.toFixed(2)}</p>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            justifyContent: 'start',
          }}
        >
          <p className="cartItemVariant">
            Color: <b>{product?.color?.label ?? 'N/A'}</b>
          </p>
          <p className="cartItemVariant">
            Tallas/Capacidad/Tamaño: <b>{product?.size?.label ?? 'N/A'}</b>
          </p>
        </div>

        <div>
          {(product?.quantity ?? 1) < 6 && (
            <p className="cartItemDelivery">
              {product?.quantity >= 6
                ? 'Se te contactara para definir la fecha de entrega.'
                : formatDateSpanish(new Date(product?.estimateDeliveryDate))}
            </p>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '1rem',
          }}
        >
          <div className="cartItemQuantity">
            <button
              style={{ width: '2rem' }}
              onClick={() => handleChange(MODE_CHANGE.less)}
            >
              <FaMinus />
            </button>
            <p>{product?.quantity ?? 1}</p>
            <button
              style={{ width: '2rem' }}
              onClick={() => handleChange(MODE_CHANGE.add)}
            >
              <FaPlus />
            </button>
          </div>
          <div style={{ width: 'fit-content' }}>
            <Button
              className="secondaryBtn"
              data-tooltip-id="comments-tooltip"
              data-tooltip-content="Ver Notas"
              data-tooltip-variant="dark"
              onClick={handleOpenComments}
            >
              <FaEye />
            </Button>
            <Tooltip id="comments-tooltip" place="left" />
          </div>
        </div>
      </div>
    </div>
  );
};
