import {
  query,
  getDocs,
  collection,
  where,
  doc,
  updateDoc,
  orderBy,
  addDoc,
  getDoc,
  onSnapshot,
} from 'firebase/firestore';
import { db, storage } from '../util/firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

export default class FirebaseService {
  static instance;

  static getInstance() {
    FirebaseService.instance ??= new FirebaseService();
    return FirebaseService.instance;
  }

  // Watches for changes in a document based on the identifier
  watch(collectionName, identifier, callback) {
    const reference = collection(db, collectionName);
    const q = query(
      reference,
      where(Object.keys(identifier)[0], '==', Object.values(identifier)[0]),
    );
    return onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.empty) return;
      querySnapshot.forEach((document) => {
        const updated = document.data();
        callback(updated);
      });
    });
  }

  // Finds a document or creates a new one if it doesn't exist
  async findOrCreate(collectionName, identifier, defaultData) {
    const reference = collection(db, collectionName);
    const q = query(
      reference,
      where(Object.keys(identifier)[0], '==', Object.values(identifier)[0]),
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      const newData = defaultData
        ? { ...defaultData, ...identifier }
        : { ...identifier };
      const newDocumentReference = await addDoc(reference, newData);
      return { id: newDocumentReference.id, ...newData };
    }
    return querySnapshot.docs.map((document) => ({
      id: document.id,
      ...document.data(),
    }));
  }

  // Finds all documents in the collection
  async findAll(collectionName, filters, orderByFields) {
    const reference = collection(db, collectionName);
    let q = query(reference);
    if (filters && filters.length > 0)
      filters.forEach((filter) => {
        q = query(q, where(filter.field, filter.operator, filter.value));
      });
    if (orderByFields && orderByFields.length > 0)
      orderByFields.forEach((order) => {
        q = query(q, orderBy(order.field, order.direction || 'asc'));
      });
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) throw new Error('No documents found');
    return querySnapshot.docs.map((document) => ({
      id: document.id,
      ...document.data(),
    }));
  }

  // Finds a document based on the identifier
  async findOne(collectionName, identifier) {
    const reference = collection(db, collectionName);
    const q = query(
      reference,
      where(Object.keys(identifier)[0], '==', Object.values(identifier)[0]),
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) throw new Error('Document not found');
    return {
      id: querySnapshot.docs[0].id,
      ...querySnapshot.docs[0].data(),
    };
  }

  // Creates a new document in the collection
  async create(collectionName, data) {
    const reference = collection(db, collectionName);
    const newDocumentReference = await addDoc(reference, data);
    return { id: newDocumentReference.id, ...data };
  }

  // Updates an existing document based on the identifier
  async update(collectionName, identifier, data) {
    const reference = collection(db, collectionName);
    const q = query(
      reference,
      where(Object.keys(identifier)[0], '==', Object.values(identifier)[0]),
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) throw new Error('Document not found for update');
    const documentReference = doc(db, collectionName, querySnapshot.docs[0].id);
    await updateDoc(documentReference, data);
    return { id: querySnapshot.docs[0].id, ...data };
  }

  // Upload a file to the storage
  async uploadFile(path, file) {
    const storageReference = ref(storage, path);
    const snapshot = await uploadBytes(storageReference, file);
    return await getDownloadURL(snapshot.ref);
  }

  async findMyOrders(uid) {
    const q = query(
      collection(db, 'Orders'),
      where('customerUid', '==', uid),
      orderBy('dateCreated', 'desc'),
    );
    const doc = await getDocs(q);
    return doc.docs.map((value) => {
      return {
        ...value.data(),
        id: value.id,
      };
    });
  }

  async findDiscountCodes() {
    const ref = collection(db, 'DiscountCode');
    const q = query(ref);
    const doc = await getDocs(q);
    return doc.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  }

  async findDrivers() {
    const q = query(collection(db, 'Users'));
    const doc = await getDocs(q);
    return doc.docs
      .map((value) => {
        const data = value.data();
        return data;
      })
      .filter((info) => info.type === 'driver');
  }

  async findUsers() {
    const q = query(collection(db, 'Users'));
    const doc = await getDocs(q);
    return doc.docs.map((value) => {
      const data = value.data();
      return data;
    });
  }

  async updateCartMigration(migrated) {
    const ref = collection(db, 'PasseioCarts');
    const q = query(ref);
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(async (doc) => {
      const docRef = doc.ref;
      await updateDoc(docRef, { migrated });
    });
  }

  async updateCart(ownerUid, newCart) {
    const ref = query(
      collection(db, 'PasseioCarts'),
      where('ownerUid', '==', ownerUid),
    );
    const findCarts = await getDocs(ref);
    findCarts.forEach(async (cart) => {
      const getCart = doc(db, 'PasseioCarts', cart.id);
      await updateDoc(getCart, newCart);
    });
  }

  async updateUser(uid, data) {
    const ref = collection(db, 'Users');
    const q = query(ref, where('uid', '==', uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(async (doc) => {
      const docRef = doc.ref;
      await updateDoc(docRef, data);
    });
  }

  async findTrips() {
    const q = query(
      collection(db, 'Trips'),
      where('date', '>', new Date().toISOString()),
      orderBy('date'),
    );
    const doc = await getDocs(q);
    return doc.docs.map((value) => {
      return value.data();
    });
  }

  async findRecommendedProducts() {
    const ref = collection(db, 'Recomendations');
    const q = query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async findCategoryWeights() {
    const ref = collection(db, 'CategoryWeight');
    const q = query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async findActiveCategoryWeights() {
    const ref = collection(db, 'CategoryWeight');
    const q = query(ref, where('status', '==', true));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async findOneCategoryWeight(id) {
    const ref = collection(db, 'CategoryWeight');
    const q = query(ref, where('category_id', '==', id));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async updateCategoryWeight(id, data) {
    const ref = collection(db, 'CategoryWeight');
    const q = query(ref, where('category_id', '==', id));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(async (doc) => {
      const docRef = doc.ref;
      await updateDoc(docRef, data);
    });
  }

  async createCategoryWeight(data) {
    const ref = collection(db, 'CategoryWeight');
    await addDoc(ref, data);
  }

  async validateCategoryWeight(id) {
    const ref = collection(db, 'CategoryWeight');
    const q = query(ref, where('category_id', '==', id));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.length === 0;
  }

  async findRecommendedCategories() {
    const ref = collection(db, 'RecommendedWishlistCategories');
    const q = query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async createHistoryProduct(data) {
    const ref = collection(db, 'historyProduct');
    await addDoc(ref, data);
  }

  async updateHistoryProduct(asin, data) {
    const ref = collection(db, 'historyProduct');
    const q = query(ref, where('asin', '==', asin));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(async (doc) => {
      const docRef = doc.ref;
      await updateDoc(docRef, data);
    });
  }

  async validateHistoryProduct(asin) {
    const ref = collection(db, 'historyProduct');
    const q = query(ref, where('asin', '==', asin));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.length === 0;
  }

  async findHistoryProducts() {
    const ref = collection(db, 'historyProduct');
    const q = query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async findOneHistoryProduct(uid) {
    const q = doc(db, 'historyProduct', uid);
    const data = await getDoc(q);
    return data.data();
  }

  async findCommonSearches() {
    const ref = collection(db, 'common-search');
    const q = query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async findOneCommonSearch(id) {
    const ref = collection(db, 'common-search');
    const q = query(ref, where('id', '==', id));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async updateCommonSearch(id, data) {
    const ref = collection(db, 'common-search');
    const q = query(ref, where('id', '==', id));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(async (doc) => {
      const docRef = doc.ref;
      await updateDoc(docRef, data);
    });
  }

  async createCommonSearch(data) {
    const ref = collection(db, 'common-search');
    await addDoc(ref, data);
  }
}
