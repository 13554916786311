import { collection, getDocs, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { db } from '../../../util/firebase';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';

export const AdminRecomendationsPage = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const categoryRef = collection(db, 'Recomendations');
        const q = query(categoryRef);
        const querySnapshot = await getDocs(q);
        const listProducts = [];
        querySnapshot.forEach((doc) => {
          const category = doc.data();
          listProducts.push({
            ...category,
            id: doc.id,
          });
        });
        if (searchQuery.trim() !== '') {
          const filteredProducts = listProducts.filter((product) =>
            product.title.toLowerCase().includes(searchQuery.toLowerCase()),
          );
          setProducts(filteredProducts);
        } else setProducts(listProducts);
      } catch {
        CustomToast(
          'error',
          'No se pudo cargar la información de las recomendaciones',
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchQuery]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = products.slice(
    indexOfFirstItem,
    indexOfFirstItem + itemsPerPage,
  );

  if (loading) return <PageLoader />;

  return (
    <div className="table-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1rem',
        }}
      >
        <Input
          type="text"
          className="mainInput"
          placeholder="Buscar..."
          style={{ marginBottom: 0 }}
          onChange={(value) => setSearchQuery(value.target.value)}
        />
        <Button
          onClick={() => navigate('/recomendation/create')}
          className="primaryBtn"
          style={{
            width: 'min-content',
            paddingLeft: '3rem',
            paddingRight: '3rem',
            marginLeft: '1rem',
          }}
        >
          Crear
        </Button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Foto</th>
            <th>ASIN</th>
            <th>Nombre</th>
            <th>Categoria</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr
              key={index}
              onClick={() => navigate(`/recomendation/${item.id}`)}
            >
              <td data-title="Foto">
                <div className="avatar-container">
                  <img
                    src={
                      item.image ||
                      'https://placehold.co/600x400?text=Not+Found'
                    }
                    alt="Avatar"
                    className="avatar"
                  />
                </div>
              </td>
              <td data-title="ASIN">{item.asin}</td>
              <td data-title="Nombre">{item.title}</td>
              <td data-title="Categoria">{item.nameCategory}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={'Anterior'}
        nextLabel={'Siguiente'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(products.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        previousClassName={'previous'}
        nextClassName={'next'}
        disabledClassName={'disabled'}
      />
    </div>
  );
};
