import { PRODUCT_SOURCES } from '../contants';

export const findHistoryItem = (history, asin) => {
  return history?.find((item) => item?.asin === asin && item?.status);
};

export function orderByPrice(array) {
  return array.sort((a, b) => {
    const priceA =
      a.price?.value ||
      a.buybox_winner?.join_prime_price?.value ||
      a.buybox_winner?.price?.value ||
      a.price ||
      1;
    const priceB =
      b.price?.value ||
      a.buybox_winner?.join_prime_price?.value ||
      b.buybox_winner?.price?.value ||
      b.price ||
      1;
    return priceB - priceA;
  });
}

export function getMaxWeight(categoryWeights, categoryList) {
  const validWeights = categoryWeights
    .filter(
      (item) =>
        categoryList?.includes(item?.category_id?.toLowerCase()) &&
        !isNaN(parseFloat(item?.weight)),
    )
    .map((item) => parseFloat(item.weight));
  return validWeights.length ? Math.max(...validWeights) : null;
}

export function sortMixedSources(array) {
  const sortedArray = [];
  const sourceIndices = PRODUCT_SOURCES.reduce((acc, source) => {
    acc[source] = 0;
    return acc;
  }, {});
  const maxCount = 4;
  let currentSourceIndex = 0;
  while (sortedArray.length < array.length) {
    const source = PRODUCT_SOURCES[currentSourceIndex];
    let count = 0;
    while (sourceIndices[source] < array.length && count < maxCount) {
      const item = array[sourceIndices[source]];
      if (item?.source === source) {
        sortedArray.push(item);
        count++;
      }
      sourceIndices[source]++;
    }
    currentSourceIndex = (currentSourceIndex + 1) % PRODUCT_SOURCES.length;
  }

  return sortedArray;
}
