import axiosInstance from '../util/axios-req';

export default class AmazonService {
  static instance;

  static getInstance() {
    AmazonService.instance ??= new AmazonService();
    return AmazonService.instance;
  }

  async search(query, page = 1) {
    const response = await axiosInstance.get(`/amazon/search`, {
      params: {
        domain: 'com',
        query,
        page,
        postal_code: '77071',
      },
    });
    return response.data;
  }

  async deals() {
    const response = await axiosInstance.get(`/amazon/search`, {
      params: {
        domain: 'com',
        query: 'ofertas',
        page: 1,
        postal_code: '77071',
      },
    });
    return response.data;
  }

  async detail(asin) {
    const response = await axiosInstance.get(`/amazon/detail`, {
      params: {
        domain: 'com',
        asin,
        postal_code: '77071',
      },
    });
    return response.data;
  }

  async reviews(product_id) {
    const response = await axiosInstance.get(`/amazon/reviews`, {
      params: {
        product_id,
      },
    });
    return response.data;
  }
}
