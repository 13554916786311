import React from 'react';
import { Modal } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { ProductReportForm } from '../../../Forms/ProductReportForm';

const ProductReportModal = ({ isOpen, toggle, product }) => {
  return (
    <Modal id="productModal" isOpen={isOpen} toggle={toggle} size="lg" centered>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          marginBottom: '1rem',
        }}
      >
        <IoClose onClick={toggle} />
      </div>
      <div>
        <ProductReportForm
          toggle={toggle}
          product={product}
          modeView="product"
        />
      </div>
    </Modal>
  );
};

export default ProductReportModal;
