import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDateSpanish } from '../../../util/date';
import { useAuth } from '../../../hooks/useAuth';
import ReactPaginate from 'react-paginate';
import { Input } from 'reactstrap';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import FirebaseService from '../../../services/firebase.service';

export function OrdersPage() {
  const [orders, setOrders] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const { profile } = useAuth();
  const navigate = useNavigate();
  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    const fetchData = async () => {
      if (!profile?.uid) return;
      try {
        const response = await firebaseService.findMyOrders(profile?.uid);
        if (searchQuery.trim() !== '') {
          const filteredItems = response.filter((item) =>
            item?.product?.nameProduct
              .toLowerCase()
              .includes(searchQuery.toLowerCase()),
          );
          setOrders(filteredItems);
        } else setOrders(response);
      } catch {
        CustomToast('error', 'No se pudo cargar la información de los pedidos');
      }
    };

    fetchData();
  }, [searchQuery]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = orders?.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="page">
      <h2>Pedidos</h2>
      <div className="table-container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '1rem',
          }}
        >
          <Input
            type="text"
            className="mainInput"
            placeholder="Buscar..."
            style={{ marginBottom: 0 }}
            onChange={(value) => setSearchQuery(value.target.value)}
          />
        </div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Foto</th>
              <th>Producto</th>
              <th>Pendiente de Pago</th>
              <th>Total</th>
              <th>Fecha de Entrega</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((item, index) => {
              let pedding =
                parseFloat(item?.total) - parseFloat(item?.canceledAmout);
              if (pedding < 0) pedding = 0;
              return (
                <tr key={index} onClick={() => navigate(`/orders/${item?.id}`)}>
                  <td data-title="ID">{item?.id}</td>
                  <td data-title="Foto">
                    <div className="avatar-container">
                      <img
                        src={
                          item?.product?.urlPhoto ||
                          'https://placehold.co/600x400?text=Not+Found'
                        }
                        alt="Avatar"
                        className="avatar"
                      />
                    </div>
                  </td>
                  <td data-title="Producto">{item.product?.nameProduct}</td>
                  <td data-title="Pendiente de Pago">
                    $
                    {parseFloat(item.total || 0) -
                      parseFloat(item.canceledAmout || 0) >
                    0
                      ? (
                          parseFloat(item.total || 0) -
                          parseFloat(item.canceledAmout || 0)
                        ).toFixed(2)
                      : '0'}
                  </td>
                  <td data-title="Total">
                    ${parseFloat(item.total).toFixed(2) || 0}
                  </td>
                  <td data-title="Fecha de Entrega">
                    {formatDateSpanish(new Date(item.arrivalDate))}
                  </td>
                  <td data-title="Estado">{item.status}</td>
                  <td data-title="Acciones">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => navigate(`/orders/${item?.id}`)}
                      style={{
                        backgroundColor: '#FFD000',
                        borderRadius: 10,
                        border: 'none',
                        color: 'black',
                        fontWeight: '600',
                      }}
                    >
                      Ver Detalles
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ReactPaginate
          previousLabel={'Anterior'}
          nextLabel={'Siguiente'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(orders?.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          previousClassName={'previous'}
          nextClassName={'next'}
          disabledClassName={'disabled'}
        />
      </div>
    </div>
  );
}
