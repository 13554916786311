import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  query,
  collection,
  getDocs,
  where,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../../../util/firebase';
import { MdChevronLeft } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';
import { Input, Button, Modal } from 'reactstrap';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { CustomToast } from '../../../components/Notifications/CustomToast';

export function AdminEditDriverPage() {
  const [isLoading, setIsLoading] = useState(false);

  const [userId, setUserId] = useState();
  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userPhone, setUserPhone] = useState();
  const [userDriverStatus, setUserDriverStatus] = useState();
  const [userDriverCapacity, setUserDriverCapacity] = useState();
  const [userData, setUserData] = useState();

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (!id) return;
      try {
        setIsLoading(true);
        const q = query(collection(db, 'Users'), where('uid', '==', id));
        const doc = await getDocs(q);
        const data = doc.docs[0].data();
        setUserData(data);
        setUserId(data.uid);
        setUserName(data.firstName + ' ' + data.lastName);
        setUserEmail(data.email);
        setUserPhone(data.phone);
        setUserDriverStatus(data.driverStatus);
        setUserDriverCapacity(data.driverCapacity);
      } catch {
        CustomToast('error', 'No se pudo cargar la información del viajero');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  const saveChanges = async () => {
    try {
      setIsLoading(true);
      const userRef = query(collection(db, 'Users'), where('uid', '==', id));
      const findUsers = await getDocs(userRef);
      findUsers.forEach(async (user) => {
        const getUser = doc(db, 'Users', user?.id);
        await updateDoc(getUser, {
          driverStatus: userDriverStatus,
        });
      });
    } catch {
      CustomToast('error', 'No se pudo guardar los cambios');
    } finally {
      setIsLoading(false);
    }
  };

  const [addDriverCapacityModalIsVisible, setAddDriverCapacityModalIsVisible] =
    useState(false);
  const toggleAddDriverCapacityModal = () => {
    setAddDriverCapacityModalIsVisible(!addDriverCapacityModalIsVisible);
  };
  const [newDriverCapacity, setNewDriverCapacity] = useState();

  const driverCapacityOptions = [
    'Appliances',
    'Arts, Crafts & Sewing',
    'Automotive',
    'Baby',
    'Beauty',
    'Books',
    'CDs & Vinyl',
    'Cell Phones & Accessories',
    'Clothing, Shoes & Jewelry',
    'Collectibles & Fine Arts',
    'Electronics',
    'Gift Cards',
    'Grocery & Gourmet Food',
    'Handmade',
    'Health & Personal Care',
    'Home & Kitchen',
    'Industrial & Scientific',
    'Movies & TV',
    'Musical Instruments',
    'Office Products',
    'Patio, Lawn & Garden',
    'Pet Supplies',
    'Sports & Outdoors',
    'Tools & Home Improvement',
    'Toys & Games',
    'Vehicles',
    'Video Games',
  ];

  const removeDriverCapacity = async () => {};

  const addDriverCapacity = async () => {};

  if (isLoading) return <PageLoader />;

  return (
    <div id="accountPage" className="page">
      <h2>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Viajero
      </h2>
      <p className="label">ID</p>
      <Input
        type="text"
        className="mainInput"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
        placeholder="El ID del viajero"
        disabled
      />
      <p className="label">Nombre</p>
      <Input
        type="text"
        className="mainInput"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        placeholder="El nombre del viajero"
        disabled
      />
      <p className="label">Correo</p>
      <Input
        type="text"
        className="mainInput"
        value={userEmail}
        onChange={(e) => setUserEmail(e.target.value)}
        placeholder="El correo del viajero"
        disabled
      />
      <p className="label">Teléfono</p>
      <Input
        type="text"
        className="mainInput"
        value={userPhone}
        onChange={(e) => setUserPhone(e.target.value)}
        placeholder="El teléfono del viajero"
        disabled
      />
      <p className="label">Aprovado como viajero</p>
      <Input
        type="select"
        className="mainInput"
        value={userDriverStatus}
        onChange={(e) => setUserDriverStatus(e.target.value)}
        placeholder="El status de viajero del usuario"
      >
        <option value={'Activo'}>Activo</option>
        <option value={'Inactivo'}>Inactivo</option>
      </Input>
      <p className="label">Capacidad</p>
      {userDriverCapacity?.map((item, index) => {
        return (
          <div key={index} className="driverCapacityItem">
            <p>{item}</p>
            <IoClose onClick={removeDriverCapacity} />
          </div>
        );
      })}
      <Button
        onClick={toggleAddDriverCapacityModal}
        className="primaryBtn"
        style={{ marginTop: '0.5rem' }}
      >
        Agregar
      </Button>
      <Modal
        isOpen={addDriverCapacityModalIsVisible}
        toggle={toggleAddDriverCapacityModal}
        size="lg"
        centered
      >
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
          >
            <IoClose onClick={toggleAddDriverCapacityModal} />
          </div>
          <p className="label">Capacidad</p>
          <Input
            type="select"
            className="mainInput"
            value={newDriverCapacity}
            onChange={(e) => setNewDriverCapacity(e.target.value)}
            defaultValue={''}
          >
            <option disabled value={''}>
              La nueva capacidad del viajero
            </option>
            {driverCapacityOptions.map((item, index) => {
              if (!userDriverCapacity?.includes(item)) {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              }
            })}
          </Input>
          <Button
            onClick={addDriverCapacity}
            className="primaryBtn"
            style={{ marginTop: '0.5rem' }}
            disabled={!newDriverCapacity}
          >
            Agregar
          </Button>
        </div>
      </Modal>
      <Button
        onClick={saveChanges}
        className="primaryBtn"
        style={{ marginTop: '1rem' }}
        disabled={userData?.driverStatus === userDriverStatus}
      >
        Guardar
      </Button>
    </div>
  );
}
