import axiosInstance from '../util/axios-req';

export default class SheinService {
  static instance;

  static getInstance() {
    SheinService.instance ??= new SheinService();
    return SheinService.instance;
  }

  async search(keywords, page = 1) {
    const response = await axiosInstance.get(`/shein/search`, {
      params: {
        language: 'en',
        country: 'US',
        currency: 'USD',
        keywords,
        sort: '7',
        limit: '20',
        page,
      },
    });
    return response.data;
  }

  async detail(goods_id) {
    const response = await axiosInstance.get(`/shein/detail`, {
      params: {
        language: 'en',
        country: 'US',
        currency: 'USD',
        goods_id,
      },
    });
    return response.data;
  }

  async extraInfo(goods_id) {
    const response = await axiosInstance.get(`/shein/extra-info`, {
      params: {
        language: 'en',
        country: 'US',
        currency: 'USD',
        goods_id,
      },
    });
    return response.data;
  }

  async legacyDetail(goods_id) {
    const response = await axiosInstance.get(`/shein/legacy-detail`, {
      params: {
        language: 'en',
        country: 'US',
        currency: 'USD',
        goods_id,
      },
    });
    return response.data;
  }

  async legacyExtraInfo(goods_id) {
    const response = await axiosInstance.get(`/shein/legacy-extra-info`, {
      params: {
        language: 'en',
        country: 'US',
        currency: 'USD',
        goods_id,
      },
    });
    return response.data;
  }
}
