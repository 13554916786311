import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import CustomSelect from '../../../Inputs/CustomSelect/CustomSelect';
import { formatDateSpanish } from '../../../../util/date';
import { CustomToast } from '../../../Notifications/CustomToast';
import { itemExistsInCart } from '../../../../util/cart';
import { useAuth } from '../../../../hooks/useAuth';
import FirebaseService from '../../../../services/firebase.service';
import ModalLoader from '../../../Loaders/ModalLoader/ModalLoader';
import SheinService from '../../../../services/shein.service';
import { calculateSheinPrice } from '../../../../util/price';
import { DEFAULT_SHIPPING, MAX_PRODUCT_PRICE } from '../../../../contants';

const SheinAddModal = ({
  isOpen,
  toggle,
  productDefault,
  colorOptions = [],
  sizeOptions = [],
  setProductReportModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [product, setProduct] = useState(null);
  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);

  const { profile, cart, setCart } = useAuth();
  const sheinService = SheinService.getInstance();
  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    if (product === null && productDefault) {
      setProduct(productDefault);
      if (colorOptions && colorOptions?.length > 0) {
        setColor({
          value: colorOptions?.find(
            (item) => item.goods_id === productDefault.goods_id,
          ).goods_id,
          label: colorOptions?.find(
            (item) => item.goods_id === productDefault.goods_id,
          ).goods_color_name,
        });
      }
      if (sizeOptions && sizeOptions?.skc_sale_attr?.length > 0) {
        setSize({
          value:
            sizeOptions.skc_sale_attr[0]?.attr_value_list[0]?.attr_value_id,
          label:
            sizeOptions.skc_sale_attr[0]?.attr_value_list[0]?.attr_value_name,
        });
      }
    }
  }, [product, productDefault, colorOptions, sizeOptions]);

  useEffect(() => {
    const fetchData = async () => {
      if (!color) return;
      try {
        setLoading(true);
        let detailResponse = await sheinService.detail(color.value);
        if (!detailResponse.info)
          detailResponse = await sheinService.legacyDetail(color.value);
        let extraInfoResponse = await sheinService.extraInfo(color.value);
        if (!extraInfoResponse.info)
          extraInfoResponse = await sheinService.legacyExtraInfo(color.value);
        setProduct(
          {
            ...detailResponse.info,
            ...extraInfoResponse.info,
          } ?? null,
        );
      } catch {
        return null;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [color]);

  const salePrice = useMemo(() => {
    if (!product) return null;
    let price = parseFloat(product?.sale_price?.amount);
    if (sizeOptions?.sku_list?.length > 0 && size) {
      const matchingItem = sizeOptions?.sku_list.find((item) =>
        item.sku_sale_attr.some((attr) => attr.attr_value_id === size.value),
      );
      price = parseFloat(matchingItem?.price?.salePrice?.amount);
    }
    return price && price <= MAX_PRODUCT_PRICE
      ? calculateSheinPrice(price)
      : null;
  }, [product, sizeOptions, size]);

  const originalPrice = useMemo(() => {
    if (!product) return null;
    let price = parseFloat(product?.retail_price?.amount);
    if (sizeOptions?.sku_list?.length > 0 && size) {
      const matchingItem = sizeOptions?.sku_list.find((item) =>
        item.sku_sale_attr.some((attr) => attr.attr_value_id === size.value),
      );
      price = parseFloat(matchingItem?.price?.retailPrice?.amount);
    }
    return price && price <= MAX_PRODUCT_PRICE
      ? calculateSheinPrice(price)
      : null;
  }, [product, sizeOptions, size]);

  const estimateDeliveryDate = useMemo(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + DEFAULT_SHIPPING);
    return currentDate;
  }, [product]);

  const handleAddToCart = async () => {
    if (!product) return;
    if (colorOptions && colorOptions?.length > 0 && !color) {
      CustomToast('warning', 'Por favor selecciona un color');
      return;
    }
    if (sizeOptions && sizeOptions?.skc_sale_attr?.length > 0 && !size) {
      CustomToast('warning', 'Por favor selecciona una talla/capacidad/tamaño');
      return;
    }
    try {
      setLoadingCart(true);
      if (itemExistsInCart(cart.items, product)) {
        CustomToast('warning', 'Producto ya se encuentra en el carrito');
        return;
      }
      const productToAdd = {
        ...product,
        priceCalculate: salePrice,
        estimateDeliveryDate: estimateDeliveryDate?.toISOString(),
        travelerSelect: null,
        variant: null,
        color,
        size,
        createdAt: Date.now(),
      };
      const items = [...cart.items, productToAdd];
      if (profile)
        await firebaseService.updateCart(profile?.uid, { ...cart, items });
      setCart({ ...cart, items });
      CustomToast('success', 'Producto agregado al carrito');
      toggle();
    } catch {
      CustomToast('error', 'Error al agregar producto al carrito');
    } finally {
      setLoadingCart(false);
    }
  };

  return (
    <Modal id="productModal" isOpen={isOpen} toggle={toggle} size="lg" centered>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          marginBottom: '1rem',
        }}
      >
        <IoClose onClick={toggle} />
      </div>
      {loading ? (
        <ModalLoader />
      ) : (
        <>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <img
              src={product?.goods_img}
              alt="Foto"
              style={{ width: '200px', height: '200px', objectFit: 'contain' }}
            />
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
            >
              <p style={{ fontSize: '20px', fontWeight: '700' }}>
                {product?.goods_name}
              </p>
              {estimateDeliveryDate && (
                <p className="productDeliveryDate">
                  Fecha Estimada de entrega:{' '}
                  <b>{formatDateSpanish(estimateDeliveryDate)}</b>
                </p>
              )}
              {salePrice && (
                <p className="productPrice">
                  {originalPrice > salePrice && (
                    <span className="productRRP">
                      <span className="productPriceSymbol">$</span>
                      <span className="productPriceWhole">
                        {originalPrice.toFixed(2)}
                      </span>
                    </span>
                  )}
                  <span className="productDiscountedPrice">
                    <span className="productPriceSymbol">$</span>
                    <span className="productPriceWhole">
                      {salePrice.toFixed(2)}
                    </span>
                  </span>
                </p>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              justifyContent: 'space-between',
              marginTop: '1rem',
            }}
          >
            {colorOptions && colorOptions.length > 0 && (
              <div
                className="section-container"
                onClick={(e) => e.stopPropagation()}
              >
                <p className="section-title">Colores:</p>
                <CustomSelect
                  value={color}
                  setValue={setColor}
                  options={colorOptions?.map((item) => {
                    return {
                      value: item.goods_id,
                      label: item.goods_color_name,
                    };
                  })}
                />
              </div>
            )}
            {sizeOptions && sizeOptions?.skc_sale_attr?.length > 0 && (
              <div
                className="section-container"
                onClick={(e) => e.stopPropagation()}
              >
                <p className="section-title">Tallas:</p>
                <CustomSelect
                  value={size}
                  setValue={setSize}
                  options={sizeOptions.skc_sale_attr.flatMap((attr) =>
                    attr?.attr_value_list?.map((item) => ({
                      value: item.attr_value_id,
                      label: item.attr_value_name,
                    })),
                  )}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '1rem',
              gap: '1rem',
            }}
          >
            <Button className="secondaryBtn" onClick={toggle}>
              Cancelar
            </Button>
            <Button
              className="primaryBtn"
              onClick={
                salePrice ? handleAddToCart : () => setProductReportModal(true)
              }
            >
              {salePrice
                ? loadingCart
                  ? 'Agregando al carrito...'
                  : 'Agregar al carrito'
                : 'Solicitar cotizacion'}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default SheinAddModal;
