export const MAX_PRODUCTS_PER_PAGE = 8;
export const MAX_PRODUCT_PRICE = 500;
export const MAX_PRODUCT_WEIGHT = 15;
export const TAX_CAP_PRICE = 200;

export const DEFAULT_AMAZON_SHIPPING = 15;
export const DEFAULT_SHIPPING = 20;

export const DEFAULT_CART = {
  ownerUid: null,
  items: [],
};
