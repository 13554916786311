import React from 'react';
import { Button, Input } from 'reactstrap';
import './LoyaltySection.css';
import { useAuth } from '../../../hooks/useAuth';
import { Link } from 'react-router-dom';

export const LoyaltySection = ({
  loyaltyPoints,
  pointsToUse,
  setPointsToUse,
}) => {
  const { profile } = useAuth();
  const handleChange = (e) => {
    setPointsToUse(e.target.value);
    sessionStorage.setItem('points-to-use', e.target.value);
  };
  return (
    <div id="breakdownDetails" className="loyalty-section">
      <p className="cardHeader">Puntos de lealtad</p>
      {profile ? (
        profile.birthdate ? (
          <>
            <Input
              name="range"
              type="range"
              min={0}
              max={loyaltyPoints}
              value={pointsToUse}
              onChange={handleChange}
              className="loyalty-slider"
            />
            <p className="points-display">
              Usando <strong>{pointsToUse}</strong> puntos de{' '}
              <strong>{loyaltyPoints}</strong> disponibles.
            </p>
            <div className="loyalty-legend">
              <p>Se te otorgará 1 punto por cada dólar gastado.</p>
              <p>Cada 100 puntos equivalen a $1.00 de descuento.</p>
            </div>
          </>
        ) : (
          <>
            <p className="points-display">
              Para poder hacer uso de tus puntos de lealtad, por favor completa
              tu fecha de nacimiento en tu perfil.
            </p>
            <Link to="/account">
              <Button className="primaryBtn">Ir a perfil</Button>
            </Link>
          </>
        )
      ) : (
        <p className="points-display">
          Inicia sesión para poder hacer uso de tus puntos de lealtad.
        </p>
      )}
    </div>
  );
};
