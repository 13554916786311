import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getDoc,
  doc,
  updateDoc,
  collection,
  where,
  query,
  getDocs,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../../util/firebase';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { DatePicker } from 'reactstrap-date-picker';
import { MdChevronLeft } from 'react-icons/md';
import { Input, Button } from 'reactstrap';
import { getBase64 } from '../../../util/photos';
import { generateUID } from '../../../util/token';
import {
  formatDateISO,
  formatTime,
  verifiedChangeDate,
} from '../../../util/date';
import { OPTIONS_REASON } from '../../../contants';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import EmailService from '../../../services/email.service';
import FirebaseService from '../../../services/firebase.service';

export function AdminEditOrderPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState();
  const [userUid, setUserUid] = useState('');
  const [orderCustomerName, setOrderCustomerName] = useState();
  const [orderDateCreated, setOrderDateCreated] = useState();
  const [orderArrivalDate, setOrderArrivalDate] = useState(new Date());
  const [orderDestinationCountry, setOrderDestinationCountry] = useState();
  const [orderDestinationAddress, setOrderDestinationAddress] = useState();
  const [orderStatus, setOrderStatus] = useState();
  const [isDelivery, setIsDelivery] = useState(false);
  const [comment, setComment] = useState('');
  const [weigthEstimate, setWeigthEstimate] = useState('');
  const [isWeigthEstimate, setIsWeigthEstimate] = useState(false);
  const [amountCancelled, setAmountCancelled] = useState();
  const [newAmountCancelled, setNewAmountCancelled] = useState();
  const [idDocumentStore, setIdDocumentStore] = useState(null);
  const [reasonChangeDate, setReasonChangeDate] = useState('');
  const [nameProduct, setNameProduct] = useState('');
  const [asin, setAsin] = useState('');
  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);
  const [nameShop, setNameShop] = useState('');
  const [urlProduct, setUrlProduct] = useState('');
  const [urlTracking, setUrlTracking] = useState('');
  const [selectTravel, setSelectTravel] = useState(null);
  const [priceProduct, setPriceProduct] = useState(0);
  const [productNumber, setProductNumber] = useState(1);
  const [financing, setFinancing] = useState(0);
  const [priceDeliveryHome, setPriceDeliveryHome] = useState(0);
  const [photoProduct, setPhotoProduct] = useState(null);
  const [photoPreview, setPhotoPreview] = useState('');
  const [discountPreview, setDiscountPreview] = useState('0');
  const [comissionPreview, setComissionPreview] = useState(0);
  const [isEdittingDeliveryAddress, setIsEdittingDeliveryAddress] =
    useState(false);
  const [order, setOrder] = useState();
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);

  const { id } = useParams();
  const navigate = useNavigate();
  const firebaseService = FirebaseService.getInstance();
  const emailService = EmailService.getInstance();

  const driverLabel = useMemo(() => {
    if (!selectTravel) return '';
    const date = new Date(selectTravel.dateTraveled);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${selectTravel.name} - ${day}/${month}/${year}`;
  }, [selectTravel]);

  useEffect(() => {
    const fetchOrderData = async () => {
      if (!id) return;
      try {
        setIsLoading(true);
        cancelDeliveryAddressEdit();
        const q = doc(db, 'Orders', id);
        const docInfo = await getDoc(q);
        const data = docInfo.data();
        setOrderId(docInfo.id);
        setIdDocumentStore(docInfo.id);
        setOrder(data);
        setOrderCustomerName(data.customerName);
        setUserUid(data.customerUid ?? '');
        setOrderDateCreated(data.dateCreated);
        setOrderArrivalDate(data.arrivalDate);
        setOrderDestinationCountry(data.deliveryAddress.country);
        setOrderDestinationAddress(data.deliveryAddress.address);
        setIsDelivery(data.isDelivery);
        setSelectTravel(data.traveler ?? null);
        setOrderStatus(data.status);
        setWeigthEstimate(data.weigthEstimate);
        setIsWeigthEstimate(data.isWeigthEstimate);
        setNameProduct(data.product?.nameProduct ?? '');
        setColor(
          data?.product?.color?.label && data?.product?.color?.value
            ? `${data.product.color.label}-${data.product.color.value}`
            : (data?.product?.color?.label ?? ''),
        );
        setSize(
          data?.product?.size?.label && data?.product?.size?.value
            ? `${data.product.size.label}-${data.product.size.value}`
            : (data?.product?.size?.label ?? ''),
        );
        setAsin(data?.product?.asin ?? null);
        setNameShop(data?.product?.nameShop ?? '');
        setUrlProduct(data?.product?.urlProduct ?? '');
        setUrlTracking(data?.product?.urlTracking ?? '');
        setComment(data?.product?.comment ?? '');
        setPhotoPreview(data?.product?.urlPhoto ?? '');
        setPriceProduct(data?.priceProduct ?? 0);
        setProductNumber(data?.productNumber ?? 1);
        setFinancing(data?.financing ?? 0);
        setPriceDeliveryHome(data?.priceDeliveryHome ?? 0);
        setAmountCancelled(data?.canceledAmout ?? 0);
        setNewAmountCancelled(data?.canceledAmout ?? 0);
        setLoyaltyPoints(data?.loyaltyPoints ?? 0);
        if (data.reasonChangeDate) setReasonChangeDate(data.reasonChangeDate);
      } catch {
        CustomToast('error', 'Error al cargar la información del pedido');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderData();
  }, [id]);

  const isChangeDateDelivery = useMemo(() => {
    if (!order?.arrivalDate) return false;
    if (!orderArrivalDate) return false;
    if (reasonChangeDate) return true;
    const orderDate = new Date(order.arrivalDate);
    const inputDate = new Date(orderArrivalDate);
    return orderDate.getTime() !== inputDate.getTime();
  }, [orderArrivalDate, order, reasonChangeDate]);

  const totalOrder = useMemo(() => {
    const totalProductNumber =
      parseFloat(priceProduct || 0) * parseFloat(productNumber || 0);
    const deliveryCost = parseFloat(priceDeliveryHome || 0);
    const financingCost = parseFloat(financing || 0);
    const subTotal = totalProductNumber + deliveryCost + financingCost;
    let discount = 0;
    let commission = parseFloat(order?.commission || 0);
    if (order?.codeDiscount) {
      const discountValue = parseFloat(order.codeDiscount.discount);
      if (order.codeDiscount.percentage) {
        discount = parseFloat((subTotal * (discountValue / 100)).toFixed(2));
        setDiscountPreview(`${discountValue}%`);
      } else {
        discount = parseFloat(order?.discount || 0);
        setDiscountPreview(`$${discount}`);
      }
    }
    setComissionPreview(commission);
    let total =
      subTotal - discount + commission - parseFloat(loyaltyPoints || 0) / 100;
    total = Math.max(total, 0);
    return total.toFixed(2);
  }, [
    financing,
    priceProduct,
    priceDeliveryHome,
    order,
    productNumber,
    loyaltyPoints,
  ]);

  const onChangePhoto = async (file) => {
    if (!file) {
      setPhotoProduct(null);
      setPhotoPreview('');
      return;
    }
    const resPreview = await getBase64(file);
    setPhotoProduct(file);
    setPhotoPreview(resPreview);
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      if (!idDocumentStore) return;
      let urlPhoto = photoPreview;
      if (photoProduct) {
        const uidPhoto = generateUID();
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `products/${uidPhoto}-${photoProduct?.name}`,
        );
        await uploadBytes(storageRef, photoProduct).then(async () => {
          urlPhoto = await getDownloadURL(storageRef);
        });
      }
      let payload = {
        arrivalDate: orderArrivalDate,
        deliveryAddress: {
          address:
            orderDestinationAddress?.label ??
            order?.deliveryAddress?.address ??
            '',
          country: orderDestinationCountry,
        },
        isDelivery: isDelivery,
        status: orderStatus,
        weigthEstimate: weigthEstimate,
        isWeigthEstimate: isWeigthEstimate,
        product: {
          nameProduct: nameProduct,
          asin: asin,
          nameShop: nameShop,
          urlProduct: urlProduct,
          urlTracking: urlTracking,
          comment: comment,
          urlPhoto: urlPhoto,
          color: {
            value: color?.includes('-') ? color.split('-')[1] : '',
            label: color?.includes('-') ? color.split('-')[0] : (color ?? null),
          },
          size: {
            value: size?.includes('-') ? size.split('-')[1] : '',
            label: size?.includes('-') ? size.split('-')[0] : (size ?? null),
          },
        },
        priceProduct: priceProduct,
        financing: financing,
        priceDeliveryHome: priceDeliveryHome,
        total: totalOrder,
        canceledAmout: newAmountCancelled,
      };
      if (isChangeDateDelivery) {
        payload = {
          ...payload,
          reasonChangeDate: reasonChangeDate ?? 'Producto Vino Maltratado',
        };
      }
      const docRef = doc(db, 'Orders', idDocumentStore);
      await updateDoc(docRef, payload);
      if (parseInt(newAmountCancelled) > parseInt(amountCancelled)) {
        const user = await firebaseService.findOne('Users', { uid: userUid });
        await firebaseService.update(
          'Users',
          { uid: userUid },
          {
            loyaltyPoints:
              user.loyaltyPoints +
              (parseInt(newAmountCancelled) - parseInt(amountCancelled)),
          },
        );
      }
      await notifyOrderStatus();
      navigate(-1);
    } catch {
      CustomToast('error', 'Error al guardar la información del pedido');
    } finally {
      setIsLoading(false);
    }
  };

  const notifyOrderStatus = async () => {
    try {
      const dateIsoArrival = formatDateISO(new Date(orderArrivalDate));
      if (
        order.status === orderStatus &&
        formatDateISO(new Date(order.arrivalDate)) === dateIsoArrival
      )
        return;
      let typeTemplate = 'order_status';
      const q = collection(db, 'Users');
      const conditional = where('uid', '==', userUid);
      const userInfoStorage = await getDocs(query(q, conditional));
      if (userInfoStorage.empty) return;
      const userInfo = userInfoStorage.docs[0].data();
      if (orderStatus === 'Entregado') typeTemplate = 'order_delivered';
      if (orderStatus === 'Delivery o Listo para ser retirado') {
        if (isDelivery) {
          typeTemplate = 'order_delivery';
        } else {
          typeTemplate = 'order_arrived';
        }
      }
      if (formatDateISO(new Date(order.arrivalDate)) !== dateIsoArrival) {
        const verifiedAdd = verifiedChangeDate(
          order.arrivalDate,
          orderArrivalDate,
        );
        if (verifiedAdd) typeTemplate = 'order_delay';
        else typeTemplate = 'order_early';
      }
      const reasonEmail = OPTIONS_REASON[reasonChangeDate] || '';
      const messageCodding = encodeURIComponent(
        `Necesito Ayuda con este Pedido: ${orderId}`,
      );
      const messsage = `https://api.whatsapp.com/send?text=${messageCodding}&phone=+50379502151`;
      const payloadEmail = {
        to: userInfo.email || '',
        template: typeTemplate,
        context: {
          id: orderId,
          feedbackUrl: 'https://passeioapp.com/feedback',
          customerServiceUrl: messsage,
          status: {
            name: 'actualizado',
            message: `actualizado a ${orderStatus?.toLowerCase()}`,
            date: dateIsoArrival,
            trackingUrl: `https://passeioapp.com/orders/${orderId}`,
          },
          shipping: {
            name: userInfo.firstName || '',
            address:
              orderDestinationAddress || order?.deliveryAddress?.address || '',
            hours: formatTime(new Date()),
            location:
              orderDestinationAddress || order?.deliveryAddress?.address || '',
          },
          item: {
            name: nameProduct,
            quantity: productNumber,
            price: parseFloat(totalOrder),
          },
          delay: {
            name: 'Retrasado',
            reason: reasonEmail || 'Producto Vino Maltratado',
            date: dateIsoArrival,
          },
          early: {
            date: dateIsoArrival,
          },
        },
      };
      await emailService.send(payloadEmail);
    } catch {
      CustomToast('error', 'Error al enviar el correo');
    }
  };

  const cancelDeliveryAddressEdit = () => {
    setIsEdittingDeliveryAddress(false);
    setOrderDestinationAddress();
  };

  if (isLoading) return <PageLoader />;

  return (
    <div id="accountPage" className="page">
      <div style={{ display: 'flex', gap: '15px', alignItems: 'flex-start' }}>
        <h2>
          <MdChevronLeft onClick={() => navigate(-1)} />
          Pedido
        </h2>
        {orderStatus === 'Cancelado' && (
          <div style={{ marginTop: '10px' }}>
            <span className="badge text-bg-danger">Pedido Cancelado</span>
          </div>
        )}
      </div>
      <form onSubmit={onSubmit}>
        <p className="label">Codigo del Pedido</p>
        <Input
          type="text"
          className="mainInput"
          value={idDocumentStore}
          placeholder="El ID del servicio"
          disabled
        />
        <p className="label">Cliente</p>
        <Input
          type="text"
          className="mainInput"
          value={orderCustomerName}
          onChange={(e) => setOrderCustomerName(e.target.value)}
          placeholder="El nombre del cliente"
          disabled
        />
        <p className="label">Fecha creada</p>
        <Input
          type="text"
          className="mainInput"
          value={
            orderDateCreated
              ? `${new Date(orderDateCreated).toLocaleDateString('en-US').split('/')[1]}.${new Date(orderDateCreated).toLocaleDateString('en-US').split('/')[0]}.${new Date(orderDateCreated).toLocaleDateString('en-US').split('/')[2]}`
              : null
          }
          onChange={(e) => setOrderDateCreated(e.target.value)}
          placeholder="La fecha creada del servicio"
          disabled
        />
        <p className="label">Fecha de entrega</p>
        <DatePicker
          className="mainInput"
          style={{ borderRadius: 10 }}
          showClearButton={false}
          value={orderArrivalDate}
          onChange={(v) => setOrderArrivalDate(v)}
          dateFormat="DD.MM.YYYY"
          placeholder="La fecha de entrega del servicio"
        />
        {isChangeDateDelivery && (
          <>
            <p className="label">Razon del cambio de fecha de entrega</p>
            <Input
              type="select"
              className="mainInput"
              value={reasonChangeDate}
              onChange={(e) => setReasonChangeDate(e.target.value)}
              placeholder="Razon"
            >
              <option value="Producto Vino Maltratado">
                Producto vino maltratado
              </option>
              <option value="Amazon se Retraso">Amazon se retrasó</option>
              <option value="El Producto se Adelanto">
                El producto se adelantó
              </option>
              <option value="El Viajero se Retraso">
                El viajero se retrasó
              </option>
              <option value="La Fecha de entrega de Amazon se Modifico">
                La fecha de entrega de Amazon se modificó
              </option>
              <option value="Otro">Otro</option>
            </Input>
          </>
        )}
        <div style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
          <p style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Destino</p>
          <p className="label">Pais de destino</p>
          <Input
            type="text"
            className="mainInput"
            value={orderDestinationCountry}
            onChange={(e) => setOrderDestinationCountry(e.target.value)}
            placeholder="El pais de destino del servicio"
            disabled
          />
          {isEdittingDeliveryAddress ? (
            <GooglePlacesAutocomplete
              className="mainInput"
              selectProps={{
                onChange: setOrderDestinationAddress,
                value: orderDestinationAddress,
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ['sv'],
                },
              }}
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            />
          ) : (
            <Input
              type="text"
              className="mainInput"
              value={order?.deliveryAddress?.address}
              placeholder="La dirección de destino del servicio"
              disabled
              style={{ marginBottom: '1rem' }}
            />
          )}
          {isEdittingDeliveryAddress ? (
            <Button
              onClick={cancelDeliveryAddressEdit}
              className="primaryBtn"
              style={{ marginTop: '1rem' }}
            >
              Cancelar
            </Button>
          ) : (
            <Button
              onClick={() => setIsEdittingDeliveryAddress(true)}
              className="primaryBtn"
            >
              Cambiar destino
            </Button>
          )}
          <div className="form-check" style={{ marginTop: '10px' }}>
            <input
              className="form-check-input"
              type="checkbox"
              checked={isDelivery}
              onChange={(e) => setIsDelivery(e.target.checked)}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label label"
              htmlFor="flexCheckDefault"
              style={{ color: '#FFF' }}
            >
              Delivery Incluido
            </label>
          </div>
        </div>
        <p className="label">Viajero</p>
        <Input
          type="text"
          className="mainInput"
          value={driverLabel}
          placeholder="El driver del servicio"
          disabled
        />
        <p className="label">Status</p>
        <Input
          type="select"
          className="mainInput"
          value={orderStatus}
          onChange={(e) => setOrderStatus(e.target.value)}
          placeholder="El status del servicio"
        >
          <option value="Cancelado">Cancelado</option>
          <option value="Orden Confirmada">Orden Confirmada</option>
          <option value="Producto Comprado">Producto Comprado</option>
          <option value="Producto en Camino">Producto en Camino</option>
          <option value="Recibido en Sucursal">Recibido en Sucursal</option>
          <option value="Delivery o Listo para ser retirado">
            Listo para ser Entregado o Retirado
          </option>
          <option value="Entregado">Entregado</option>
        </Input>

        <p className="label">Peso del Pedido</p>
        <Input
          type="text"
          className="mainInput"
          value={weigthEstimate}
          onChange={(e) => setWeigthEstimate(e.target.value)}
          placeholder="Peso del Pedido"
        />
        <div className="form-check" style={{ marginTop: '10px' }}>
          <input
            className="form-check-input"
            type="checkbox"
            checked={isWeigthEstimate}
            onChange={(e) => setIsWeigthEstimate(e.target.checked)}
            id="weigthEstimate"
          />
          <label
            className="form-check-label label"
            htmlFor="weigthEstimate"
            style={{ color: '#FFF' }}
          >
            Es un peso Estimado
          </label>
        </div>

        <div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
          <p style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Producto</p>
          <p className="label">Nombre del Producto</p>
          <Input
            type="text"
            className="mainInput"
            value={nameProduct}
            onChange={(e) => setNameProduct(e.target.value)}
            placeholder="Nombre del Producto"
          />
          <p className="label">ASIN</p>
          <Input
            type="text"
            className="mainInput"
            value={asin}
            onChange={(e) => setAsin(e.target.value)}
            placeholder="ASIN"
          />
          <p className="label">Color</p>
          <Input
            type="text"
            className="mainInput"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            placeholder="Color"
          />
          <p className="label">Talla</p>
          <Input
            type="text"
            className="mainInput"
            value={size}
            onChange={(e) => setSize(e.target.value)}
            placeholder="Talla"
          />
          <p className="label">Nombre de la Tienda</p>
          <Input
            type="text"
            className="mainInput"
            value={nameShop}
            onChange={(e) => setNameShop(e.target.value)}
            placeholder="Nombre de la tienda"
          />
          <p className="label">Link del producto</p>
          <Input
            type="text"
            className="mainInput"
            value={urlProduct}
            onChange={(e) => setUrlProduct(e.target.value)}
            placeholder="Link del producto"
          />
          <p className="label">Link del Tracking de la tienda</p>
          <Input
            type="text"
            className="mainInput"
            value={urlTracking}
            onChange={(e) => setUrlTracking(e.target.value)}
            placeholder="Link del Tracking"
          />
          <div className="mb-3">
            <label
              htmlFor="comment"
              className="form-label"
              style={{ color: '#FFF' }}
            >
              Comentarios
            </label>
            <textarea
              className="form-control"
              id="comment"
              rows="3"
              style={{ background: 'none', color: '#FFF' }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-3" style={{ color: '#FFF' }}>
            <p htmlFor="photoProduct" className="form-label">
              Foto del Producto
            </p>
            {!photoPreview && (
              <input
                style={{ background: 'none', color: '#FFF' }}
                className="form-control"
                type="file"
                id="photoProduct"
                onChange={(e) => onChangePhoto(e.target.files?.[0] || null)}
                accept="image/*"
              />
            )}
            {photoPreview && (
              <img
                src={photoPreview}
                alt="Foto del Producto"
                width={250}
                height={200}
                style={{ objectFit: 'cover', marginTop: '10px' }}
              />
            )}
          </div>
        </div>

        <div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
          <p style={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>Costos</p>
          <p className="label">Precio del Producto</p>
          <Input
            type="number"
            className="mainInput"
            value={priceProduct}
            onChange={(e) => setPriceProduct(e.target.value)}
            placeholder="Precio del Producto"
          />
          <p className="label">Cantidad del Producto</p>
          <Input
            type="number"
            className="mainInput"
            value={productNumber}
            onChange={(e) => setProductNumber(e.target.value)}
            placeholder="Cantidad del Producto"
          />
          <p className="label">Financing</p>
          <Input
            type="number"
            className="mainInput"
            value={financing}
            onChange={(e) => setFinancing(e.target.value)}
            placeholder="Financing"
          />
          <p className="label">Home Delivery</p>
          <Input
            type="number"
            className="mainInput"
            value={priceDeliveryHome}
            onChange={(e) => setPriceDeliveryHome(e.target.value)}
            placeholder="Precio del Home Delivery"
          />
          {discountPreview !== '0' && (
            <>
              <p className="label">Descuento</p>
              <Input
                type="text"
                className="mainInput"
                value={discountPreview}
                placeholder="El costo total del pedido"
                disabled
              />
            </>
          )}
          {comissionPreview > 0 && (
            <>
              <p className="label">Comision Extra</p>
              <Input
                type="number"
                className="mainInput"
                value={comissionPreview}
                placeholder="El costo total del pedido"
                disabled
              />
            </>
          )}
          {loyaltyPoints > 0 && (
            <>
              <p className="label">Puntos de Lealtad Utilizados</p>
              <Input
                type="text"
                className="mainInput"
                value={loyaltyPoints}
                placeholder="0"
                disabled
              />
            </>
          )}
          <p className="label">Total</p>
          <Input
            type="number"
            className="mainInput"
            value={totalOrder}
            placeholder="El costo total del pedido"
            disabled
          />
          <p className="label">Monto Cancelado</p>
          <Input
            type="number"
            className="mainInput"
            value={newAmountCancelled}
            onChange={(e) => setNewAmountCancelled(e.target.value)}
            placeholder="Monto Cancelado"
          />
        </div>

        <Button type="submit" className="primaryBtn">
          Guardar
        </Button>
        <Button
          onClick={() => navigate(-1)}
          className="secondaryBtn"
          style={{ marginTop: '0.5rem' }}
        >
          Cancelar
        </Button>
      </form>
    </div>
  );
}
