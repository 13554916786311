import React from 'react';
import Select from 'react-select';

export default function CustomSelect({
  placeholder = 'Selecciona...',
  value,
  setValue,
  options,
}) {
  return (
    <Select
      placeholder={placeholder}
      value={value}
      onChange={setValue}
      options={options}
      styles={{
        control: (styles) => ({
          ...styles,
          backgroundColor: '#000000',
          borderColor: '#fdd835',
        }),
        option: (styles, { isDisabled, isFocused, isSelected }) => ({
          ...styles,
          color: isDisabled
            ? '#ccc'
            : isSelected || isFocused
              ? 'black'
              : 'white',
          backgroundColor: isDisabled
            ? null
            : isSelected
              ? '#ffd000'
              : isFocused
                ? '#fdd835'
                : null,
          cursor: isDisabled ? 'not-allowed' : 'default',
        }),
        singleValue: (styles) => ({
          ...styles,
          color: 'white',
        }),
        input: (styles) => ({
          ...styles,
          color: 'white',
        }),
        placeholder: (styles) => ({
          ...styles,
          color: 'white',
        }),
        dropdownIndicator: (styles) => ({
          ...styles,
          color: '#fdd835',
        }),
        indicatorSeparator: (styles) => ({
          ...styles,
          backgroundColor: '#fdd835',
        }),
        menu: (styles) => ({
          ...styles,
          backgroundColor: '#000000',
        }),
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: '#fdd835',
          primary: '#ffd000',
          neutral0: '#000000',
          neutral80: '#ffffff',
          neutral20: '#fdd835',
          neutral50: '#ffffff',
        },
      })}
    />
  );
}
