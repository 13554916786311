import { getItemIdKey } from './sources';

export const removeItemFromCart = (cartItems, item) => {
  const key = getItemIdKey(item?.source);
  if (!key) return cartItems;
  return cartItems.filter((dbItem) => item[key] !== dbItem[key]);
};

export const itemExistsInCart = (cartItems, item) => {
  const key = getItemIdKey(item?.source);
  return key
    ? cartItems.some((cartItem) => cartItem[key] === item[key])
    : false;
};

export const mergeCartItems = (databaseItems = [], localItems = []) => {
  return [
    ...databaseItems,
    ...localItems.filter((item) => !itemExistsInCart(databaseItems, item)),
  ];
};
