import { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import Swal from 'sweetalert2';
import { useAuth } from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import FirebaseService from '../../../services/firebase.service';

const CATEGORYS = {
  automotive: 'automotive',
  phone: 'cell phones',
  electronics: 'electronics',
  car: 'car',
  game: 'games & accessories',
  volumen: 'volumen50cm',
  weigth15: 'weigth 15',
  weigth200: 'weigth 200',
  amount6: 'amount 6',
};

export function AccountPage() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [birthdate, setBirthdate] = useState(null);
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [usedLoyaltyPoints, setUsedLoyaltyPoints] = useState(0);
  const [expoPushToken, setExpoPushToken] = useState(null);
  const [address, setAddress] = useState(null);
  const [dayBeforeTravel, setDayBeforeTravel] = useState(0);
  const [dayAfterTravel, setDayAfterTravel] = useState(0);
  const [featureDriver, setFeatureDriver] = useState([]);

  const { logOut, profile } = useAuth();
  const navigate = useNavigate();
  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    const fetchprofile = async () => {
      if (!profile) return;
      try {
        setLoading(true);
        setName(`${profile.firstName} ${profile.lastName}`);
        setEmail(profile.email);
        setPhone(profile.phone ?? null);
        setBirthdate(profile.birthdate ?? null);
        setLoyaltyPoints(profile.loyaltyPoints ?? 0);
        setUsedLoyaltyPoints(profile.usedLoyaltyPoints ?? 0);
        setExpoPushToken(profile.expoPushToken ?? null);
        setDayBeforeTravel(profile.dayBeforeTravel ?? 0);
        setDayAfterTravel(profile.dayAfterTravel ?? 0);
        setAddress(profile.address ?? null);
        setFeatureDriver(profile.featureDriver ?? []);
      } catch {
        CustomToast('error', 'No se pudo cargar la información del usuario');
      } finally {
        setLoading(false);
      }
    };
    fetchprofile();
  }, [profile]);

  const handleChangeFeature = (checked, name) => {
    if (checked) setFeatureDriver([...featureDriver, name]);
    else {
      const filter = featureDriver.filter((value) => value !== name);
      setFeatureDriver(filter);
    }
  };

  const updateInfoUser = async () => {
    try {
      setLoading(true);
      await firebaseService.updateUser(profile?.uid, {
        phone,
        birthdate,
        address,
        featureDriver,
        dayBeforeTravel,
        dayAfterTravel,
      });
      CustomToast('success', 'Informacion actualizada correctamente');
    } catch {
      CustomToast('error', 'No se pudo actualizar la informacion');
    } finally {
      setLoading(false);
    }
  };

  const logoutUser = () => {
    Swal.fire({
      text: '¿Estas segur@ que quieres cerrar sesion?',
      icon: 'warning',
      showCancelButton: true,
      background: '#111111',
      confirmButtonColor: '#FFD000',
      cancelButtonColor: '#555555',
      confirmButtonText: 'Si, cerrar sesion',
      cancelButtonText: 'No, cancelar',
    }).then((result) => {
      if (result.isConfirmed)
        logOut().then(() => navigate('/login', { replace: true }));
    });
  };

  if (loading) return <PageLoader />;

  return (
    <div id="accountPage" className="page">
      <h2>Tu cuenta</h2>
      <div style={{ marginBottom: '1rem' }}>
        <p className="label">Nombre</p>
        <Input
          type="text"
          className="mainInput"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="John Doe"
          disabled
        />

        <p className="label">Correo</p>
        <Input
          type="text"
          className="mainInput"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="johndoe@example.com"
          disabled
        />

        <p className="label">Teléfono</p>
        <Input
          type="text"
          className="mainInput"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder="0000-0000"
        />

        <p className="label">Fecha de nacimiento</p>
        <Input
          type="date"
          className="mainInput"
          placeholder="Fecha de nacimiento"
          value={birthdate}
          onChange={(e) => setBirthdate(e.target.value)}
        />

        <p className="label">Puntos de lealtad</p>
        <Input
          type="number"
          className="mainInput"
          value={loyaltyPoints}
          placeholder="0"
          disabled
        />

        <p className="label">Puntos de lealtad usados</p>
        <Input
          type="number"
          className="mainInput"
          value={usedLoyaltyPoints}
          placeholder="0"
          disabled
        />

        {profile?.isAdmin ? (
          <div>
            <p className="label">Expo push token</p>
            <Input
              type="text"
              className="mainInput"
              value={expoPushToken}
              onChange={(e) => setExpoPushToken(e.target.value)}
              placeholder="rYYulRrzgq"
              disabled
            />
          </div>
        ) : null}

        {profile?.type === 'driver' && (
          <>
            <div className="mb-3">
              <label
                htmlFor="comment"
                className="form-label"
                style={{ color: '#FFF' }}
              >
                Direccion
              </label>
              <textarea
                className="form-control"
                id="comment"
                rows="2"
                style={{ background: 'none', color: '#FFF' }}
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              ></textarea>
            </div>
            <p className="label">
              Cuantos Dias antes de tu viaje recibes pedidos?
            </p>
            <Input
              type="number"
              className="mainInput"
              placeholder="0"
              onChange={(e) => setDayBeforeTravel(e.target.value)}
              value={dayBeforeTravel}
            />
            <p className="label">
              Cuantos Dias despues de tu viaje entregas los pedidos
            </p>
            <Input
              type="number"
              className="mainInput"
              placeholder="0"
              onChange={(e) => setDayAfterTravel(e.target.value)}
              value={dayAfterTravel}
            />

            <p className="label">Caracteristicas de los productos</p>
            <div style={{ display: 'flex', gap: '15px' }}>
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    onChange={(e) =>
                      handleChangeFeature(
                        e.target.checked,
                        CATEGORYS.automotive,
                      )
                    }
                    checked={featureDriver.includes(CATEGORYS.automotive)}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="flexCheckDefault"
                  >
                    Automotriz
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="checkPhone"
                    onChange={(e) =>
                      handleChangeFeature(e.target.checked, CATEGORYS.phone)
                    }
                    checked={featureDriver.includes(CATEGORYS.phone)}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="checkPhone"
                  >
                    Celulares
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="electronicCheck"
                    onChange={(e) =>
                      handleChangeFeature(
                        e.target.checked,
                        CATEGORYS.electronics,
                      )
                    }
                    checked={featureDriver.includes(CATEGORYS.electronics)}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="electronicCheck"
                  >
                    Electronicos
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="carCheck"
                    onChange={(e) =>
                      handleChangeFeature(e.target.checked, CATEGORYS.car)
                    }
                    checked={featureDriver.includes(CATEGORYS.car)}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="carCheck"
                  >
                    Vehiculos
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="gameCheck"
                    onChange={(e) =>
                      handleChangeFeature(e.target.checked, CATEGORYS.game)
                    }
                    checked={featureDriver.includes(CATEGORYS.game)}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="gameCheck"
                  >
                    VideoJuegos
                  </label>
                </div>
              </div>
              <div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="volumenCheck"
                    onChange={(e) =>
                      handleChangeFeature(e.target.checked, CATEGORYS.volumen)
                    }
                    checked={featureDriver.includes(CATEGORYS.volumen)}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="volumenCheck"
                  >
                    Volumen ({`>50 cm o equivalente`})
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="weight15Check"
                    onChange={(e) =>
                      handleChangeFeature(e.target.checked, CATEGORYS.weigth15)
                    }
                    checked={featureDriver.includes(CATEGORYS.weigth15)}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="weight15Check"
                  >
                    Peso ({`>15`})
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="weight200Check"
                    onChange={(e) =>
                      handleChangeFeature(e.target.checked, CATEGORYS.weigth200)
                    }
                    checked={featureDriver.includes(CATEGORYS.weigth200)}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="weight200Check"
                  >
                    Precio ({`>200`})
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="amount6Check"
                    onChange={(e) =>
                      handleChangeFeature(e.target.checked, CATEGORYS.amount6)
                    }
                    checked={featureDriver.includes(CATEGORYS.amount6)}
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="amount6Check"
                  >
                    Cantidad ({`>6`})
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <Button onClick={!loading && updateInfoUser} className="primaryBtn">
          {loading ? 'Actualizando...' : 'Actualizar'}
        </Button>

        <Button onClick={logoutUser} className="secondaryBtn">
          Cerrar sesion
        </Button>
      </div>
    </div>
  );
}
