import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Modal,
} from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../util/firebase';
import { Link, useParams } from 'react-router-dom';
import styles from './OrderDetailsPage.module.css';
import { formatDateSpanish } from '../../../util/date';
import { OPTIONS_REASON } from '../../../contants';
import PageLoader from '../../../components/Loaders/PageLoader/PageLoader';
import { CustomToast } from '../../../components/Notifications/CustomToast';

const STEP_STATUS = {
  'Orden Confirmada': 1,
  'Producto Comprado': 2,
  'Producto en Camino': 3,
  'Recibido en Sucursal': 4,
  'Delivery o Listo para ser retirado': 5,
  Entregado: 6,
};

export const OrderDetailsPage = () => {
  const [loading, setLoading] = useState(true);
  const [activeModal, setActiveModal] = useState(false);
  const [infoOrder, setInfoOrder] = useState(null);
  const [modeMobile, setModeMobile] = useState(window.innerWidth < 960);
  const { id } = useParams();

  const encodedMsg = encodeURIComponent(
    `Necesito Ayuda con este Pedido: https://passeioapp.com/orders/${id}`,
  );
  const whatsappMsg = `https://api.whatsapp.com/send?text=${encodedMsg}&phone=+50379502151`;

  useEffect(() => {
    if (!id) return;
    try {
      const referenciaDocumento = doc(db, 'Orders', id);
      const unsubscribe = onSnapshot(referenciaDocumento, (doc) => {
        if (doc.exists()) setInfoOrder(doc.data());
      });
      return () => unsubscribe();
    } catch {
      CustomToast('error', 'No se pudo cargar la información del pedido');
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!infoOrder) return;
    const element = document.getElementById('comment');
    if (!element) return;

    const commentOrder = infoOrder?.product?.comment || '';
    const textWithJumpSpace = commentOrder.replace(/\n/g, '<br>');

    element.innerHTML = textWithJumpSpace;
  }, [infoOrder]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const dateCreated = useMemo(() => {
    if (!infoOrder) return '';
    return new Date(infoOrder.dateCreated);
  }, [infoOrder]);

  const dateDelivery = useMemo(() => {
    if (!infoOrder) return '';
    const date = new Date(infoOrder.arrivalDate);

    return formatDateSpanish(date);
  }, [infoOrder]);

  const statusOrder = useMemo(() => {
    if (!infoOrder) return;

    const step = STEP_STATUS[infoOrder.status];
    return step || 0;
  }, [infoOrder]);

  const labelDate = useMemo(() => {
    if (!infoOrder) return 'Fecha de entrega';

    if (infoOrder.status === 'Delivery o Listo para ser retirado')
      return 'Listo para ser Retirado desde';
    if (infoOrder.status === 'Entregado') return 'Entregado el';

    return 'Fecha de entrega';
  }, [infoOrder]);

  const peddingAmount = useMemo(() => {
    if (!infoOrder) return 0;
    let pedding =
      parseFloat(infoOrder.total || 0) -
      parseFloat(infoOrder.canceledAmout || 0);

    if (pedding < 0) {
      pedding = 0;
    }

    return pedding;
  }, [infoOrder]);

  const reasonChangeDate = useMemo(() => {
    if (!infoOrder) return '';
    const label = OPTIONS_REASON[infoOrder.reasonChangeDate];

    if (label) return label;

    return '';
  }, [infoOrder]);

  const onClose = () => {
    setActiveModal(false);
  };

  const handleResize = () => {
    setModeMobile(window.innerWidth < 960);
  };

  if (loading) return <PageLoader />;

  return (
    <div className="page">
      <div>
        <h1>Detalles del pedido</h1>
      </div>
      <Card
        className="my-2"
        inverse
        style={{
          width: '100%',
          textAlign: 'center',
          backgroundColor: '#222222',
          color: 'white',
          borderRadius: 10,
          padding: '1rem',
        }}
      >
        <CardHeader style={{ padding: 0, borderBottom: 'none' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              gap: '15px',
              flexWrap: 'wrap',
            }}
          >
            <div>
              <p style={{ fontWeight: '700', marginBottom: 0 }}>
                Fecha de pedido
              </p>
              {dateCreated && <p>{formatDateSpanish(dateCreated)}</p>}
            </div>
            <div>
              <p style={{ fontWeight: '700', marginBottom: 0 }}>Total</p>
              <p>${infoOrder?.total}</p>
            </div>
            <div>
              <p style={{ fontWeight: '700', marginBottom: 0 }}>Cantidad</p>
              <p>{infoOrder?.productNumber}</p>
            </div>
            {peddingAmount > 0 && (
              <div>
                <p style={{ fontWeight: '700', marginBottom: 0 }}>
                  Pendiente de Pago
                </p>
                <p>${peddingAmount.toFixed(2)}</p>
              </div>
            )}
            <div>
              <p style={{ fontWeight: '700', marginBottom: 0 }}>
                Dirección de entrega
              </p>
              <p>{infoOrder?.deliveryAddress?.address}</p>
            </div>
            <div>
              <p>
                <span style={{ fontWeight: '700' }}>Orden #:</span> {id}
              </p>
            </div>
          </div>
        </CardHeader>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            width: '100%',
          }}
        >
          <div>
            <div className={styles.contentHelp}>
              <p style={{ fontSize: '25px', textAlign: 'left', margin: 0 }}>
                <span style={{ fontWeight: '700' }}>{labelDate}: </span>{' '}
                {dateDelivery}
              </p>
              <div className={styles.buttonHelp}>
                <Link to={whatsappMsg} target="_blank" rel="noreferrer">
                  <Button className="primaryBtn">Necesito ayuda</Button>
                </Link>
              </div>
            </div>
            {statusOrder !== STEP_STATUS.Entregado &&
              statusOrder !==
                STEP_STATUS['Delivery o Listo para ser retirado'] && (
                <>
                  {infoOrder?.reasonChangeDate && (
                    <p
                      style={{
                        fontSize: '16px',
                        textAlign: 'left',
                        margin: 0,
                        marginTop: modeMobile ? '10px' : 0,
                      }}
                    >
                      <span style={{ fontWeight: '700' }}>
                        {' '}
                        Razón del cambio de fecha:{' '}
                      </span>{' '}
                      {reasonChangeDate}
                    </p>
                  )}
                </>
              )}
            <ListGroup>
              <ListGroupItem className={styles.contentInfo}>
                <div className="productContainer" style={{ marginTop: '1rem' }}>
                  <div
                    className="productImageContainer"
                    style={{ marginBottom: '0.5rem' }}
                  >
                    <img
                      alt="Imagen del producto"
                      src={infoOrder?.product?.urlPhoto}
                      className="productImage"
                      style={{
                        width: '140px',
                        height: '140px',
                      }}
                    />
                  </div>
                </div>
                <div>
                  <p className={styles.title}>
                    {infoOrder?.product?.nameProduct}
                  </p>
                  {infoOrder?.product?.color && (
                    <p className={styles.variant}>
                      Color: <b>{infoOrder?.product?.color?.label}</b>
                    </p>
                  )}
                  {infoOrder?.product?.size && (
                    <p className={styles.variant}>
                      Talla/Capacidad/Tamaño:{' '}
                      <b>{infoOrder?.product?.size?.label}</b>
                    </p>
                  )}
                  <p id="comment">{infoOrder?.product?.comment}</p>
                </div>
              </ListGroupItem>
            </ListGroup>
          </div>
        </div>
        <CardFooter
          style={
            modeMobile
              ? undefined
              : {
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '10px 30px 5px 30px',
                }
          }
        >
          <div>
            <div className="progress-control">
              <div className="progress-bar-content">
                <div
                  className="progress-tracker"
                  style={
                    modeMobile
                      ? { height: `${statusOrder * 16.66}%` }
                      : { width: `${statusOrder * 16.66}%` }
                  }
                ></div>
                <div className="circle-content">
                  {[...Array(6)].map((_, index) => (
                    <div
                      key={index}
                      className={`circle-control`}
                      style={{ cursor: 'inherit' }}
                    >
                      {' '}
                    </div>
                  ))}
                </div>
              </div>
              <div className="labels-control">
                <p>Orden Confirmada</p>
                <p>Producto Comprado</p>
                <p>Producto en Camino</p>
                <p>Recibido en Sucursal</p>
                <p>Listo para ser entregado</p>
                <p>Entregado</p>
              </div>
            </div>
          </div>
        </CardFooter>
      </Card>
      <Modal isOpen={activeModal} size="lg" centered>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              marginBottom: '1rem',
            }}
          >
            <IoClose onClick={onClose} />
          </div>
        </div>
      </Modal>
    </div>
  );
};
