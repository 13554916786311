import axiosInstance from '../util/axios-req';

export default class PaymentService {
  static instance;

  static getInstance() {
    PaymentService.instance ??= new PaymentService();
    return PaymentService.instance;
  }

  async create3ds(payload) {
    const response = await axiosInstance.post(`/wompi/3ds`, payload);
    return response.status === 201 ? response.data : false;
  }
}
